import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistedStore } from "./Redux/store";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
const root = ReactDOM.createRoot(document.getElementById("root"));
const theme = extendTheme({
  components: {
    Select: {
      baseStyle: {
          field: {
              _placeholder: {
                  color: 'gray.400',
              },
          },
      },
  },
    Modal: {
      sizes: {
        xl: {
          dialog: {
            maxW: "70%",
            minH: "80%",
            borderRadius: "md",
          },
        },
        ct: {
          dialog: {
            maxW: "50%",
            borderRadius: "md",
          },
        },
      },
    },
  },
});
root.render(
  <Provider store={store}>
  <PersistGate persistor={persistedStore}>
  <ChakraProvider theme={theme}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ChakraProvider>
  </PersistGate>
  </Provider>
);
