import axios from "axios";
import * as types from "./ActionTypes";
export const initialPurchase = () => async (dispatch) => {
  dispatch({ type: types.INITIAL_PURCHASE_REQUEST });
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/purchase`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PURCHASE_KEY}`,
        },
      }
    );
    dispatch({ type: types.INITIAL_PURCHASE_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: types.INITIAL_PURCHASE_FAILURE });
    console.log(err);
  }
};
export const updateRefNo = (value) => (dispatch) => {
  dispatch({ type: types.UPDATE_REFERENCE_SUCCESS, payload: `RM-00${value}` });
};
const convertToTimestamp = (dateStr, timeStr) => {
  // Parse the date string in DD/MM/YYYY format
  const [day, month, year] = dateStr.split("/").map(Number);

  // Parse the time string in 12-hour format
  const [time, period] = timeStr.split(" ");
  const [hours, minutes, seconds] = time.split(":").map(Number);

  // Convert 12-hour time to 24-hour time
  let hours24 = hours;
  if (period === "PM" && hours !== 12) {
    hours24 += 12;
  } else if (period === "AM" && hours === 12) {
    hours24 = 0;
  }

  // Create a Date object
  const date = new Date(year, month - 1, day, hours24, minutes, seconds);

  // Get Unix timestamp (seconds since January 1, 1970)
  return Math.floor(date.getTime() / 1000);
};
const getMaxTimepayment = (e) => {
  try {
    let finaldatea = 0;
    e.description.forEach((el) => {
      const data = el.stage
        .reverse()
        .filter(
          (f) =>
            f.stage.toLowerCase() == "partial payment" ||
            f.stage.toLowerCase() == "full payment"
        );
      if (finaldatea < convertToTimestamp(data[0].date, data[0].time)) {
        finaldatea = convertToTimestamp(data[0].date, data[0].time);
      }
    });
    return finaldatea;
  } catch (error) {}
};
export const allPaymentData = (token) => (dispatch) => {
  try {
    dispatch({ type: types.ALL_PURCHASE_REQUEST });
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/purchase/allpurchase`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        res.data.data = res.data.data.sort(
          (a, b) => getMaxTimepayment(b) - getMaxTimepayment(a)
        );
        dispatch({ type: types.ALL_PURCHASE_SUCCESS, payload: res.data.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: types.ALL_PURCHASE_FAILURE });
      });
  } catch (error) {
    console.log(error);
  }
};
