import { Box, Button } from '@chakra-ui/react'
import React from 'react'
import { IoReorderThreeOutline } from "react-icons/io5";
const MobileNav = ({draweropen}) => {
  return (
    <Box>
      <Button onClick={draweropen}><IoReorderThreeOutline /></Button>
    </Box>
  )
}

export default MobileNav
