import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Table,
  Tbody,
  Th,
  Thead,
  Flex,
  Input,
  Text,
  ModalHeader,
  useDisclosure,
  Image,
  Center,
  useToast,
  useSteps,
  Step,
  StepIndicator,
  StepStatus,
  StepIcon,
  StepNumber,
  StepTitle,
  Stepper,
  StepSeparator,
  Select,
  Textarea,
  SimpleGrid,
  Td,
  Tr,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Tabs,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  InputLeftElement,
  InputGroup,
  Icon,
  PopoverHeader,
  DrawerCloseButton,
  ModalFooter,
} from "@chakra-ui/react";
import logo from "../Resources/logo.png";
import { ReactId } from "reactjs-id";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Redux/AuthReducer/Action";
import axios from "axios";
import default_product_image from "../Resources/default_product_image.png";
import {
  deleteProduction,
  dispatchcreateOrder,
  fetchProduction,
  filterYear,
  getAllData,
  holdUpdate,
  loaderUpdate,
  searchProduct,
  shipByPartyProduct,
  tabUpdate,
  updateOrderDetails,
} from "../Redux/AppReducer/Action";
import Login from "./Login";
import InsertProduct from "../Component/InsertProduct";
import { capitalizeWords } from "../Component/capitalWords";
import ImageUploadComponent from "../Component/ImageUploadComponent";
import EditProduct from "../Component/EditProduct";
import Refresh from "../Component/Refresh";
import Loading from "../Component/Loading";
import {
  admin_user,
  courier_partner,
  exclude_stage,
  hold_list,
  table_filter,
} from "../universal_variable";
import { WarningTwoIcon } from "@chakra-ui/icons";
import TableForm from "../Component/TableForm";
import { useLocation, useNavigate } from "react-router";
import MobileNav from "../Component/MobileNav";
import { useSearchParams } from "react-router-dom";
import { sockets } from "../emitter";
import { FaSearch } from "react-icons/fa";
import Permissions from "../Component/Permissions";
import PaymentImageUpload from "../Component/PaymentImageUpload";
import CouponGenerate from "../Component/CouponGenerate";
import ProfileButton from "../Component/ProfileButton";
const Besic = ({ setFinalData, finalData }) => {
  const [filterDealer, setFilterDealer] = useState([]);
  const [filterEmployee, setFilterEmployee] = useState([]);
  const dataList = useSelector((store) => store.app.dataList);
  const dealer_code = useRef(null);
  const orderBy = useRef(null);

  return (
    <Box mt={"10px"}>
      <Text hidden={window.innerWidth>800} fontSize={"20px"} fontWeight={"bold"} mb={"10px"}>
        <Center>
          1. Besic Data
        </Center>
      </Text>
      <Flex alignItems={"center"} mb={"10px"} justifyContent={"space-between"} direction={["column","column","row","row"]}>
        <Text mt={"5px"} w={"150px"}>
          Dealer Code / Name
        </Text>
        <Flex w={"70%"} direction={"column"}>
          <Input placeholder="Type Dealer Code / Name"
            ref={dealer_code}
            defaultValue={
              finalData?.orderData?.dealer?.distributor_code != undefined
                ? `${finalData?.orderData?.dealer?.distributor_name}(${finalData?.orderData?.dealer?.distributor_code})`
                : ""
            }
            onKeyUp={(e) => {
              if (
                e.key == "Backspace" &&
                dealer_code.current.value.includes("(")
              ) {
                dealer_code.current.value = "";
                setFilterDealer([]);
                const temp = { ...finalData };
                temp.orderData.dealer = {};
                setFinalData(temp);
              } else {
                const filetr_dealer = dataList?.Dealer.filter(
                  (ele) =>
                    ele.distributor_name
                      .toLowerCase()
                      .includes(dealer_code.current.value.toLowerCase()) ||
                    ele.distributor_code
                      .toLowerCase()
                      .includes(dealer_code.current.value.toLowerCase())
                );
                if (dealer_code.current.value == "") {
                  setFilterDealer([]);
                  const temp = { ...finalData };
                  temp.orderData.dealer = {};
                  setFinalData(temp);
                } else {
                  setFilterDealer(filetr_dealer);
                }
              }
            }}
          />
          <Box
            hidden={filterDealer.length <= 0}
            maxH={"150px"}
            overflow={"scroll"}
            scrollMarginX={"inherit"}
            sx={{
              "::-webkit-scrollbarX": {
                display: "none",
              },
            }}
          >
            {filterDealer.map((elem, index) => {
              const { distributor_name, distributor_code } = elem;
              return (
                <Box
                  key={index}
                  onClick={() => {
                    dealer_code.current.value = `${distributor_name}(${distributor_code})`;
                    const temp = { ...finalData };
                    temp.orderData.dealer = elem;
                    setFinalData(temp);
                    setFilterDealer([]);
                    dealer_code.current.focus();
                  }}
                  cursor={"pointer"}
                  borderBottom={"1px solid black"}
                >
                  {distributor_name}({distributor_code})
                </Box>
              );
            })}
          </Box>
        </Flex>
      </Flex>
      <Flex
        mt={"10px"}
        justifyContent={"space-between"}
        hidden={finalData?.orderData?.dealer?.address == undefined}
        direction={["column","column","row","row"]} alignItems={"center"}
      >
        <Text mt={"5px"} w={"150px"}>
          Dealer Address:
        </Text>
        <Textarea
          w={"70%"}
          resize={"none"}
          defaultValue={
            finalData?.orderData?.dealer?.address != undefined
              ? finalData?.orderData?.dealer?.address
              : ""
          }
          cursor={"not-allowed"}
          _hover={{}}
          readOnly
        />
      </Flex>
      <Flex justifyContent={"space-between"} mt={"10px"} direction={["column","column","row","row"]} alignItems={"center"}>
        <Text mt={"5px"}>Order Taken By</Text>
        <Flex w={"70%"} direction={"column"}>
          <Input
            defaultValue={
              finalData?.orderData?.orderBy?.biller_name != undefined
                ? `${finalData?.orderData?.orderBy?.biller_name}(${finalData?.orderData?.orderBy?.bc_code})`
                : ""
            }
            placeholder="Type Employee Name"
            ref={orderBy}
            onKeyUp={(e) => {
              if (e.key == "Backspace" && orderBy.current.value.includes("(")) {
                orderBy.current.value = "";
                setFilterEmployee([]);
                const temp = { ...finalData };
                temp.orderData.orderBy = {};
                setFinalData(temp);
              } else {
                const filetr_dealer = dataList?.Employee.filter(
                  (ele) =>
                    ele.bc_code
                      .toLowerCase()
                      .includes(orderBy.current.value.toLowerCase()) ||
                    ele.biller_name
                      .toLowerCase()
                      .includes(orderBy.current.value.toLowerCase())
                );
                if (orderBy.current.value == "") {
                  setFilterEmployee([]);
                  const temp = { ...finalData };
                  temp.orderData.orderBy = {};
                  setFinalData(temp);
                } else {
                  setFilterEmployee(filetr_dealer);
                }
              }
            }}
          />
          <Box
            hidden={filterEmployee.length <= 0}
            maxH={"150px"}
            overflow={"scroll"}
            scrollMarginX={"inherit"}
            sx={{
              "::-webkit-scrollbarX": {
                display: "none",
              },
            }}
          >
            {filterEmployee.map((elem, index) => {
              const { biller_name, bc_code } = elem;
              return (
                <Box
                  key={index}
                  onClick={() => {
                    orderBy.current.value = `${biller_name}(${bc_code})`;
                    const temp = { ...finalData };
                    temp.orderData.orderBy = elem;
                    setFinalData(temp);
                    setFilterEmployee([]);
                    orderBy.current.focus();
                  }}
                  cursor={"pointer"}
                  borderBottom={"1px solid black"}
                >
                  {biller_name}({bc_code})
                </Box>
              );
            })}
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};
const Description = ({ setFinalData, finalData, initImage }) => {
  const title = useRef(null);
  const toast = useToast();
  const description = useRef(null);
  const [selectedImage, setSelectedImage] = useState([]);
  const [displayImage, setdisplayImage] = useState(initImage);
  const handleImageUpload = (event, pos) => {
    let pics = selectedImage;
    let blob = [...displayImage];
    if (event.target.files[0] !== undefined) {
      pics[selectedImage.length] = {
        pic: event.target.files[0],
        position: pos + 1,
      };
      blob[pos] = URL.createObjectURL(event.target.files[0]);
      setSelectedImage(pics);
      setdisplayImage(blob);
      event.target.value = "";
    }
  };
  const handleDeleteImage = (e, pos) => {
    e.preventDefault();
    let pics = selectedImage.filter((ele, i) => i !== pos);
    let blob = [...displayImage];
    URL.revokeObjectURL(blob[pos]);
    blob[pos] = default_product_image;
    setSelectedImage(pics);
    setdisplayImage(blob);
  };
  const addProduct = () => {
    if (title.current.value == "" || description.current.value == "") {
      toast({
        title: "Fill all fields",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const formData = new FormData();
    for (let i = 0; i < selectedImage.length; i++) {
      formData.append(
        "myFile",
        selectedImage[i].pic,
        `${finalData.orderID.split("-")[0]}_${
          finalData.orderDescription.length
        }_${selectedImage[i].position}.jpg`
      );
      formData.append(
        "imagename",
        `${finalData.orderID.split("-")[0]}_${
          finalData.orderDescription.length
        }_${selectedImage[i].position}.jpg`
      );
    }
    formData.append("title", title.current.value);
    formData.append("description", description.current.value);

    const temp = { ...finalData };
    temp.orderDescription.push(formData);
    setFinalData(temp);
    setdisplayImage(initImage);
    setSelectedImage([]);
    title.current.value = "";
    description.current.value = "";
  };
  return (
    <Box mt={"10px"}>
      <Flex direction={"column"} >
        <Input ref={title} mt={"10px"} placeholder="Title" />
        <Flex gap={"10px"}direction={["column","column","row","row"]}>
          <Textarea
            ref={description}
            w={["100%","100%","50%","50%"]}
            mt={"10px"}
            resize={"none"}
            h={"250px"}
            placeholder="Description"
          />
          <Box>
            <SimpleGrid columns={[3,3,3,4]} gap={"5px"}>
              <ImageUploadComponent
                position={0}
                handleDeleteImage={handleDeleteImage}
                handleImageUpload={handleImageUpload}
                displayImage={displayImage}
              />
              <ImageUploadComponent
                position={1}
                handleDeleteImage={handleDeleteImage}
                handleImageUpload={handleImageUpload}
                displayImage={displayImage}
              />
              <ImageUploadComponent
                position={2}
                handleDeleteImage={handleDeleteImage}
                handleImageUpload={handleImageUpload}
                displayImage={displayImage}
              />
              <ImageUploadComponent
                position={3}
                handleDeleteImage={handleDeleteImage}
                handleImageUpload={handleImageUpload}
                displayImage={displayImage}
              />
              <ImageUploadComponent
                position={4}
                handleDeleteImage={handleDeleteImage}
                handleImageUpload={handleImageUpload}
                displayImage={displayImage}
              />
              <ImageUploadComponent
                position={5}
                handleDeleteImage={handleDeleteImage}
                handleImageUpload={handleImageUpload}
                displayImage={displayImage}
              />
              <ImageUploadComponent
                position={6}
                handleDeleteImage={handleDeleteImage}
                handleImageUpload={handleImageUpload}
                displayImage={displayImage}
              />
              <ImageUploadComponent
                position={7}
                handleDeleteImage={handleDeleteImage}
                handleImageUpload={handleImageUpload}
                displayImage={displayImage}
              />
            </SimpleGrid>
          </Box>
        </Flex>
        <Button mt={"10px"} colorScheme="blue" onClick={addProduct}>
          Add Product
        </Button>
      </Flex>
      <Table
        variant={"striped"}
        hidden={finalData.orderDescription.length <= 0}
      >
        <Thead>
          <Th>
            <Center>Sl No.</Center>
          </Th>
          <Th>
            <Center>Title</Center>
          </Th>
          <Th minW={"400px"}>
            <Center>Description</Center>
          </Th>
          <Th>Action</Th>
        </Thead>
        <Tbody>
          {finalData?.orderDescription?.map((ele, i) => {
            const images = [];
            for (const entry of ele.entries()) {
              const [name, value] = entry;
              if (name === "myFile") {
                const imageUrl = URL.createObjectURL(value);
                images.push(imageUrl);
              }
            }
            return (
              <Tr key={i}>
                <Td>
                  <Center>{i + 1}</Center>
                </Td>
                <Td>
                  <Box whiteSpace={"break-spaces"} maxW={"300px"}>
                    {ele.get("title")}
                  </Box>
                </Td>
                <Td>
                  <Box whiteSpace={"break-spaces"} maxW={"300px"}>
                    {ele.get("description")}
                  </Box>
                  <SimpleGrid mt={"10px"} columns={4} gap={"10px"}>
                    {images.map((img, ind) => {
                      return (
                        <Popover key={ind}>
                          <PopoverTrigger>
                            <Image h={"50px"} src={img} alt={ind} />
                          </PopoverTrigger>
                          <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverBody>
                              <Image h={"300px"} src={img} alt={ind} />
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      );
                    })}
                  </SimpleGrid>
                </Td>
                <Td>
                  <Button
                    colorScheme="red"
                    onClick={() => {
                      const temp = { ...finalData };
                      temp.orderDescription.splice(i, 1);
                      setFinalData(temp);
                    }}
                  >
                    Delete
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};
const Dates = ({ setFinalData, finalData }) => {
  const currentDate = new Date().toISOString().split("T")[0];

  useEffect(() => {
    const date = currentDate.split("-").reverse().join("/");
    const temp = { ...finalData };
    temp.dates = { ...temp.dates, orderDate: date };
    // setFinalData(temp);
  }, []);

  return (
    <Box mt={"10px"}>
      <Flex gap={"3px"}>
        <Text w={"166px"} mt={"5px"}>
          Order Date:
        </Text>
        <Input
          onChange={(e) => {
            const date = e.target.value.split("-").reverse().join("/");
            const temp = { ...finalData };
            temp.dates = { ...temp.dates, orderDate: date };
            setFinalData(temp);
          }}
          defaultValue={currentDate}
          w={"200px"}
          type="date"
        />
      </Flex>
      <Flex gap={"3px"} mt={"10px"}>
        <Text mt={"5px"}>Expected Delivery Date:</Text>
        <Input
          onChange={(e) => {
            const date = e.target.value.split("-").reverse().join("/");
            const temp = { ...finalData };
            temp.dates = { ...temp.dates, expectedDate: date };
            setFinalData(temp);
          }}
          w={"200px"}
          type="date"
        />
      </Flex>
    </Box>
  );
};
const Home = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const setParams = (key, categoryValue) => {
    const params = new URLSearchParams(searchParams);
    params.set(key, categoryValue);
    setSearchParams(params.toString());
  };
  const dataList = useSelector((store) => store.app.dataList);
  const months = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];
  const {
    isOpen: isMobileNavOpen,
    onOpen: onMobileNavOpen,
    onClose: onMobileNavClose,
  } = useDisclosure();
  const HomeMenuButtons = ({ tabIndex }) => {
    const generatePDF = async () => {
      try {
        dispatch(loaderUpdate(true));
        const data = production_sheet.filter((e) => e.checked == true)[0];
        data.description = data.description.filter((product) => {
          const stages = product?.stage?.filter(
            (e) => exclude_stage.includes(e.stage) == false
          );
          const lastStage = stages[stages.length - 1].stage;
          if (
            lastStage == "queue for dispatchment" ||
            table_filter.readyDispatch.includes(lastStage)
          ) {
            return true;
          }
        });
        data.dispatchBy = user.first_name;
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/generatepdf`,
          data,
          {
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch(loaderUpdate(false));
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(pdfBlob);
        link.download = `${data.trackingID}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        dispatch(loaderUpdate(false));
        console.log(error);
      }
    };
    return (
      <Flex direction={["column", "column", "row", "row"]} gap={"10px"}>
        <Button
          fontSize={"12px"}
          isDisabled={
            production_sheet.filter((ele) => ele.checked == true).length <= 0 ||
            production_sheet.filter((ele) => ele.checked == true).length > 1
          }
          hidden={
            window.innerWidth > 800
              ? user.first_name == undefined || ![2, 3].includes(tabIndex)
              : false
          }
          mb={"10px"}
          colorScheme="blue"
          onClick={generatePDF}
        >
          Generate Dispatch Report
        </Button>
        <Button
          fontSize={"12px"}
          isDisabled={
            production_sheet.filter((ele) => ele.checked == true).length <= 0 ||
            production_sheet.filter((ele) => ele.checked == true).length > 1
          }
          hidden={
            window.innerWidth > 800
              ? user.first_name == undefined || tabIndex != 2
              : false
          }
          mb={"10px"}
          colorScheme="blue"
          onClick={() => {
            dispatch(loaderUpdate(true));
            handleDispatch("payment pending", ["queue for dispatchment"]);
            dispatch(loaderUpdate(false));
          }}
        >
          Add to Payment Clearance
        </Button>
        <Button
          isDisabled={
            production_sheet.filter((ele) => ele.checked == true).length <= 0 ||
            production_sheet.filter((ele) => ele.checked == true).length > 1
          }
          hidden={
            window.innerWidth > 800
              ? user.first_name == undefined || tabIndex != 3
                ? true
                : user.usertype == "bc"
                ? false
                : !admin_user.includes(user.email)
              : false
          }
          mb={"10px"}
          fontSize={"12px"}
          colorScheme="blue"
          onClick={() => {
            dispatch(loaderUpdate(true));
            handleDispatch("partial payment", [
              "payment pending",
              "partial payment",
            ]);
            dispatch(loaderUpdate(false));
          }}
        >
          Mark as Partial Payment
        </Button>
        <Button
          fontSize={"12px"}
          isDisabled={
            production_sheet.filter((ele) => ele.checked == true).length <= 0 ||
            production_sheet.filter((ele) => ele.checked == true).length > 1
          }
          hidden={
            window.innerWidth > 800
              ? user.first_name == undefined || tabIndex != 3
                ? true
                : user.usertype == "bc"
                ? false
                : !admin_user.includes(user.email)
              : false
          }
          mb={"10px"}
          colorScheme="blue"
          onClick={() => {
            dispatch(loaderUpdate(true));
            handleDispatch("full payment", [
              "payment pending",
              "partial payment",
              "full payment",
            ]);
            dispatch(loaderUpdate(false));
          }}
        >
          Mark as Full Payment
        </Button>
        <Button
          isDisabled={
            production_sheet.filter((ele) => ele.checked == true).length <= 0 ||
            production_sheet.filter((ele) => ele.checked == true).length > 1
          }
          hidden={
            window.innerWidth > 800
              ? user.first_name == undefined || tabIndex != 3
              : false
          }
          mb={"10px"}
          colorScheme="blue"
          fontSize={"12px"}
          onClick={() => {
            dispatch(loaderUpdate(true));
            handleDispatch("ready for dispatchment", [
              "queue for dispatchment",
              "full payment",
              "partial payment",
              "payment pending",
            ]);
            dispatch(loaderUpdate(false));
          }}
        >
          Ready For Dispatch
        </Button>
        <Button
          fontSize={"12px"}
          isDisabled={
            production_sheet.filter((ele) => ele.checked == true).length <= 0 ||
            production_sheet.filter((ele) => ele.checked == true).length > 1
          }
          hidden={
            window.innerWidth > 800
              ? user.first_name == undefined || tabIndex != 2
              : false
          }
          mb={"10px"}
          colorScheme="blue"
          onClick={() => {
            dispatch(loaderUpdate(true));
            handleDispatch("return from dispatchment", [
              "queue for dispatchment",
            ]);
            dispatch(loaderUpdate(false));
          }}
        >
          Cancel Dispatch
        </Button>
        <Button
          fontSize={"12px"}
          isDisabled={
            production_sheet.filter((ele) => ele.checked == true).length <= 0 ||
            production_sheet.filter((ele) => ele.checked == true).length > 1
          }
          hidden={user.first_name == undefined || tabIndex != 4}
          mb={"10px"}
          colorScheme="blue"
          onClick={onUpdateAllAlertOpen}
        >
          Add / Update Shipping
        </Button>
        <Button
          fontSize={"12px"}
          isDisabled={
            production_sheet.filter((ele) => ele.checked == true).length <= 0 ||
            production_sheet.filter((ele) => ele.checked == true).length > 1
          }
          hidden={user.first_name == undefined || tabIndex > 2}
          mb={"10px"}
          colorScheme="blue"
          onClick={onInsertProductOpen}
        >
          Insert Product
        </Button>
        <Button
          fontSize={"12px"}
          isDisabled={
            production_sheet.filter((ele) => ele.checked == true).length <= 0
          }
          hidden={user.first_name == undefined || tabIndex > 2}
          mb={"10px"}
          colorScheme="red"
          onClick={onDeleteAlertOpen}
        >
          Delete
        </Button>
        <Button
          fontSize={"12px"}
          hidden={user.first_name == undefined || tabIndex == 2}
          mb={"10px"}
          colorScheme="green"
          onClick={() => {
            onAddOpen();
            setStep(0);
            setActiveStep(0);
            setFinalData(initalData);
          }}
        >
          Add New Order
        </Button>
        {window.innerWidth <= 800 ? <></> : <Refresh refresh={fetchData} />}
        <Menu
          hidden={user.first_name == undefined || tabIndex == 2}
          placement="bottom-end"
          mr={"15px"}
        >
          <MenuButton
            fontSize={"12px"}
            as={Button}
            hidden={user.first_name == undefined || tabIndex == 2}
          >
            More
          </MenuButton>
          <MenuList fontSize={"14px"}>
            <MenuItem
              fontSize={"14px"}
              onClick={() => {
                navigate("/report");
              }}
            >
              Reports
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/payment");
              }}
            >
              Payment Proofs
            </MenuItem>
            <MenuItem
              isDisabled={
                production_sheet.filter((ele) => ele.checked == true).length <=
                  0 ||
                production_sheet.filter((ele) => ele.checked == true).length > 1
              }
              onClick={() => {
                navigate(
                  `/order/${
                    production_sheet.filter((ele) => ele.checked == true)[0]
                      .trackingID
                  }`
                );
              }}
            >
              Order Report
            </MenuItem>
            <MenuItem
              isDisabled={
                production_sheet.filter((ele) => ele.checked == true).length <=
                  0 ||
                production_sheet.filter((ele) => ele.checked == true).length > 1
              }
              onClick={() => {
                onCouponGenerateOpen();
              }}
            >
              Generate Coupon
            </MenuItem>
            <MenuItem
              isDisabled={
                production_sheet.filter((ele) => ele.checked == true).length <=
                0
              }
            >
              Update/Add Inv. Number
            </MenuItem>
            <MenuItem
              isDisabled={
                production_sheet.filter((ele) => ele.checked == true).length <=
                0
              }
            >
              Cancel Order
            </MenuItem>
            <MenuItem
              isDisabled={
                production_sheet.filter((ele) => ele.checked == true).length <=
                0
              }
            >
              Invoice Number
            </MenuItem>
            <MenuItem
              isDisabled={
                production_sheet.filter((ele) => ele.checked == true).length <=
                0
              }
            >
              Download QR
            </MenuItem>
          </MenuList>
        </Menu>
        <Button
          fontSize={"12px"}
          hidden={user.first_name != undefined}
          mb={"10px"}
          colorScheme={user.first_name == undefined ? "blue" : "red"}
          onClick={handleLogin}
        >
          {user.first_name == undefined ? "Login" : "Logout"}
        </Button>
        <ProfileButton handleLogin={handleLogin} />
      </Flex>
    );
  };
  const steps = [
    { title: "Besic Data" },
    { title: "Description" },
    { title: "Dates" },
  ];
  const initImage = [
    default_product_image,
    default_product_image,
    default_product_image,
    default_product_image,
    default_product_image,
    default_product_image,
    default_product_image,
    default_product_image,
  ];
  const [step, setStep] = useState(0);
  const [stages, setProgress] = useState([]);
  const [shipping, setShipping] = useState({});
  const holdRef = useRef();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });
  const {
    isOpen: isLoginOpen,
    onOpen: onLoginOpen,
    onClose: onLoginClose,
  } = useDisclosure();
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isProgressOpen,
    onOpen: onProgressOpen,
    onClose: onProgressClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteAlertOpen,
    onOpen: onDeleteAlertOpen,
    onClose: onDeleteAlertClose,
  } = useDisclosure();
  const {
    isOpen: isShippingAlertOpen,
    onOpen: onShippingAlertOpen,
    onClose: onShippingAlertClose,
  } = useDisclosure();
  const {
    isOpen: isHoldgAlertOpen,
    onOpen: onHoldAlertOpen,
    onClose: onHoldAlertClose,
  } = useDisclosure();
  const {
    isOpen: isUpdateAllAlertOpen,
    onOpen: onUpdateAllAlertOpen,
    onClose: onUpdateAllAlertClose,
  } = useDisclosure();
  const {
    isOpen: isInsertProductOpen,
    onOpen: onInsertProductOpen,
    onClose: onInsertProductClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteProductOpen,
    onOpen: onDeleteProductOpen,
    onClose: onDeleteProductClose,
  } = useDisclosure();
  const {
    isOpen: isSelfProductOpen,
    onOpen: onSelfProductOpen,
    onClose: onSelfProductClose,
  } = useDisclosure();
  const {
    isOpen: isUpdateOpen,
    onOpen: onUpdateOpen,
    onClose: onUpdateClose,
  } = useDisclosure();
  const {
    isOpen: isPaymentUploadOpen,
    onOpen: onPaymentUploadOpen,
    onClose: onPaymentUploadClose,
  } = useDisclosure();
  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();
  const {
    isOpen: isCouponGenerateOpen,
    onOpen: onCouponGenerateOpen,
    onClose: onCouponGenerateClose,
  } = useDisclosure();
  const user = useSelector((store) => store.auth.user);
  const token = useSelector((store) => store.auth.token);
  const filterYears = useSelector((store) => store.app.filteryear);
  const toast = useToast();
  const dispatch = useDispatch();
  const pickupRef = useRef();
  const courier = useRef(null);
  const cancelRef = useRef(null);
  const awb = useRef(null);
  const yearRef = useRef(null);
  const production_sheet = useSelector((store) => store.app.orderList);
  const orderCount = useSelector((store) => store.app.orderCount);
  const pickupParty = useSelector((store) => store.app.selfpickup);
  const load = useSelector((store) => store.app.isLoading);
  const fetchData = () => {
    dispatch(fetchProduction(searchParams));
  };
  const holdValue = useSelector((store) => store.app.hold);
  useEffect(() => {
    setParams("year", new Date().getFullYear());
    fetchData();
    dispatch(getAllData(dataList, "both"));
  }, []);
  useEffect(() => {
    fetchData();
  }, [window.location.href]);
  useEffect(() => {
    // Define handlers
    const handleDataUpdate = () => {
      fetchData(); // Define this function as needed
    };

    const handleAllYear = (data) => {
      dispatch(filterYear(data));
    };

    // Set up socket event listeners
    sockets.on("dataUpdate", handleDataUpdate);
    sockets.on("allYear", handleAllYear);

    // Cleanup on unmount
    return () => {
      sockets.off("dataUpdate", handleDataUpdate);
      sockets.off("allYear", handleAllYear);
    };
  }, [dispatch]); // Ensure dependencies are correctly specified
  const handleLogin = () => {
    if (user.first_name == undefined) {
      onLoginOpen();
    } else {
      dispatch(logout());
    }
  };
  const currentDate = new Date().toISOString().split("T")[0];
  const date = currentDate.split("-").reverse().join("/");
  const initalData = {
    orderID: ReactId(),
    orderData: {},
    orderDescription: [],
    dates: { orderDate: date },
  };
  const [finalData, setFinalData] = useState(initalData);
  const [tempCourier, setTempCourier] = useState("");
  const createOrder = () => {
    try {
      const orderdate = new Date(
        finalData?.dates?.orderDate?.split("/")?.reverse()?.join("/")
      );
      const expecteddate = new Date(
        finalData?.dates?.expectedDate?.split("/")?.reverse()?.join("/")
      );
      if (finalData.dates.expectedDate == undefined) {
        toast({
          title: "Fill All Fields",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      } else if (expecteddate < orderdate) {
        toast({
          title: "Expected Date should be greater than Order Date",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      } else {
        dispatch(dispatchcreateOrder(finalData, toast, token))
          .then(() => {
            onAddClose();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleHold = () => {
    const { data, position, id, value, time } = shipping;
    const currentDate = new Date().toISOString().split("T")[0];
    const date = currentDate.split("-").reverse().join("/");
    data[position].stage.push({
      date: date,
      time: time,
      stage: value,
      reason: holdValue !== "others" ? holdValue : holdRef.current.value,
      updateBy: user.first_name,
    });
    dispatch(updateOrderDetails(JSON.stringify(data), id, token)).then(() => {
      toast({
        title: "Stage Updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onHoldAlertClose();
    });
  };
  const handleCancel = () => {
    if (cancelRef.current.value == undefined || cancelRef.current.value == "") {
      toast({
        title: "Fill the reason to cancel",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const { data, position, id, value, time } = shipping;
    const currentDate = new Date().toISOString().split("T")[0];
    const date = currentDate.split("-").reverse().join("/");
    data[position].stage.push({
      date: date,
      time: time,
      stage: value,
      reason: cancelRef.current.value,
      updateBy: user.first_name,
    });
    dispatch(updateOrderDetails(JSON.stringify(data), id, token)).then(() => {
      toast({
        title: "Stage Updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onCancelClose();
    });
  };
  const handleUpdateShipping = () => {
    if (awb?.current?.value == "" || courier?.current?.value == "") {
      toast({
        title: "Fill all fields",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      const { data, position, id, value, time } = shipping;
      const currentDate = new Date().toISOString().split("T")[0];
      const date = currentDate.split("-").reverse().join("/");
      data[position].stage.push({
        date: date,
        time: time,
        stage: value,
        awb: awb.current.value,
        courier: courier.current.value,
        updateBy: user.first_name,
      });
      dispatch(updateOrderDetails(JSON.stringify(data), id, token)).then(() => {
        toast({
          title: "Stage Updated",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      });
      onShippingAlertClose();
    }
  };
  const handleUpdateOrder = useCallback(
    (data, value, position, id) => {
      try {
        const currentDate = new Date().toISOString().split("T")[0];
        const newDate = new Date();
        let hours = newDate.getHours();
        const minutes = newDate.getMinutes();
        const seconds = newDate.getSeconds();
        const amPm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12;

        const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")} ${amPm}`;
        const date = currentDate.split("-").reverse().join("/");
        if (value == "cancel" || value=="cancelled by courier") {
          setShipping({
            data,
            id,
            position,
            value,
            time: formattedTime,
          });
          onCancelOpen();
        } else if (value == "hold") {
          setShipping({
            data,
            id,
            position,
            value,
            time: formattedTime,
          });
          onHoldAlertOpen();
        } else if (value == "shipping booked" || value == "update shipping") {
          setShipping({
            data,
            id,
            position,
            value,
            time: formattedTime,
          });
          onShippingAlertOpen();
        } else if (value == "self pickup") {
          dispatch(
            shipByPartyProduct({
              date,
              data,
              id,
              position,
              value,
              time: formattedTime,
            })
          );
          onSelfProductOpen();
        } else {
          data[position].stage.push({
            date: date,
            time: formattedTime,
            stage: value,
            updateBy: user.first_name,
          });
          dispatch(updateOrderDetails(JSON.stringify(data), id, token)).then(
            () => {
              toast({
                title: "Stage Updated",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
            }
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    [
      dispatch,
      token,
      user.first_name,
      onHoldAlertOpen,
      onShippingAlertOpen,
      onSelfProductOpen,
      toast,
    ]
  );
  const handleDeleteOrder = () => {
    const fdata = production_sheet
      .filter((ele) => ele.checked == true)
      .map((ele) => ele.id);
    dispatch(deleteProduction(fdata, token, production_sheet));
  };

  const handleTabChange = (index) => {
    dispatch(tabUpdate(index));
  };
  const UpdateAllShipping = () => {
    const currentDate = new Date().toISOString().split("T")[0];
    const date = currentDate.split("-").reverse().join("/");
    const newDate = new Date();
    let hours = newDate.getHours();
    const minutes = newDate.getMinutes();
    const seconds = newDate.getSeconds();
    const amPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")} ${amPm}`;
    const product = production_sheet.filter((e) => e.checked == true);
    let finalData = product[0];
    finalData.description = finalData.description.map((e) => {
      const stage = e.stage.filter((s) => s.stage == "shipping booked");
      const payload = {
        awb: awb.current.value,
        courier: courier.current.value,
        date,
        time: formattedTime,
        stage: "",
        updateBy: user.first_name,
      };
      if (stage.length <= 0) {
        payload.stage = "shipping booked";
      } else {
        payload.stage = "update shipping";
      }
      e.stage.push(payload);
      return e;
    });

    dispatch(
      updateOrderDetails(
        JSON.stringify(finalData.description),
        finalData.id,
        token
      )
    ).then(() => {
      toast({
        title: "Stage Updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onShippingAlertClose();
    });
  };
  const handlePickupProduct = () => {
    try {
      const { id, position, data, date, value, time } = pickupParty;
      data[position].stage.push({
        stage: value,
        time,
        date,
        details: pickupRef.current.value,
        updateBy: user.first_name,
      });
      dispatch(updateOrderDetails(JSON.stringify(data), id, token)).then(() => {
        toast({
          title: "Stage Updated",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleDispatch = (state, lastStage) => {
    let sameStageCount = 0;
    let readyDispatchCount = 0;
    let requestCount = 0;
    let rejectCount = 0;
    let stageModifyCount = 0;
    const currentDate = new Date().toISOString().split("T")[0];
    const date = currentDate.split("-").reverse().join("/");
    const newDate = new Date();
    let hours = newDate.getHours();
    const minutes = newDate.getMinutes();
    const seconds = newDate.getSeconds();
    const amPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")} ${amPm}`;
    const product = [...production_sheet.filter((e) => e.checked == true)];
    let finalData = product[0];
    finalData.description = finalData.description.map((e) => {
      const payload = {
        date,
        time: formattedTime,
        stage: state,
        updateBy: user.first_name,
      };
      let removeHold = [];
      e?.stage?.forEach((elen) => {
        if (elen.stage == "hold") {
          removeHold = [];
        } else {
          removeHold.push(elen);
        }
      });
      const stages = removeHold.filter(
        (e) => exclude_stage.includes(e.stage) == false
      );
      if (
        stages[stages?.length - 1]?.stage == state &&
        stages[stages?.length - 1]?.request == "pending"
      ) {
        sameStageCount++;
      }
      if (
        (stages[stages?.length - 1]?.stage == "full payment" ||
          stages[stages?.length - 1]?.stage == "partial payment") &&
        stages[stages?.length - 1]?.request == "reject" &&
        state == "ready for dispatchment"
      ) {
        rejectCount++;
      }
      if (
        stages[stages?.length - 1]?.stage == "payment pending" &&
        state == "ready for dispatchment"
      ) {
        readyDispatchCount++;
      }
      if (stages[stages?.length - 1]?.request == "pending") {
        requestCount++;
      }
      if (lastStage.includes(stages[stages?.length - 1]?.stage)) {
        if (state == "partial payment" || state == "full payment") {
          payload.request = "accept";
        }
        e.stage.push(payload);
        stageModifyCount++;
      }
      return e;
    });
    if (
      requestCount > 0 ||
      readyDispatchCount > 0 ||
      sameStageCount > 0 ||
      rejectCount > 0
    ) {
      finalData.description = finalData.description.map((e) => {
        e.stage.pop();
        return e;
      });
      toast({
        title:
          requestCount > 0
            ? "Wait For Accept from Admin"
            : readyDispatchCount > 0
            ? "Update Payment First"
            : sameStageCount > 0
            ? "Same Stage Exist"
            : rejectCount > 0
            ? "Your Request is Rejected By Admin"
            : null,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (stageModifyCount == 0) {
      toast({
        title: "Request Failed to Update",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (state == "partial payment" || state == "full payment") {
      const ordername = finalData.orderData.orderBy.biller_name;
      if (ordername == user.first_name || admin_user.includes(user.email)) {
        onPaymentUploadOpen();
        return;
      } else {
        toast({
          title: "You do not have access for this order",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
    }
    dispatch(
      updateOrderDetails(
        JSON.stringify(finalData.description),
        finalData.id,
        token
      )
    )
      .then(() => {
        toast({
          title: "Stage Updated",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        fetchData();
      })
      .catch(() => {
        toast({
          title: "Error in Stage Update",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };
  let timeID;
  const handlesearch = (value, delay) => {
    if (timeID) {
      clearTimeout(timeID);
    }
    timeID = setTimeout(() => {
      if (value != "") {
        console.log(value);
        dispatch(searchProduct(value, token));
      } else {
        fetchData();
      }
    }, delay);
  };
  const monthorder = useSelector((store) => store.app.monthorder)
    ?.toString()
    .padStart(2, "0");
  const tabIndex = useSelector((store) => store.app.tabIndex);
  return (
    <Box>
      <Loading load={load} />
      <Permissions isUpdateOpen={isUpdateOpen} onUpdateClose={onUpdateClose} />
      <PaymentImageUpload
        isOpen={isPaymentUploadOpen}
        onClose={onPaymentUploadClose}
      />
      <CouponGenerate
        isCouponGenerateOpen={isCouponGenerateOpen}
        onCouponGenerateClose={onCouponGenerateClose}
      />
      <Modal isCentered size={window.innerWidth<=800?"full":"xl"} isOpen={isAddOpen} onClose={onAddClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Box>
              <Text>Create Production</Text>
              <Flex mt={"10px"} justifyContent={"space-between"}>
                <Button
                  isDisabled={step <= 0}
                  onClick={() => {
                    setStep((prev) => prev - 1);
                    setActiveStep(step - 1);
                  }}
                  colorScheme="red"
                >
                  Prev
                </Button>
                <Button
                  isDisabled={step > 2}
                  onClick={() => {
                    if (
                      step == 0 &&
                      (finalData?.orderData?.dealer?.address == undefined ||
                        finalData?.orderData?.orderBy?.biller_name == undefined)
                    ) {
                      toast({
                        title: "Fill all fields",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                      });
                    } else if (
                      step == 1 &&
                      finalData?.orderDescription?.length <= 0
                    ) {
                      toast({
                        title: "Add Order Details",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                      });
                    } else if (step == 2) {
                      createOrder();
                    } else {
                      setStep((prev) => prev + 1);
                      setActiveStep(step + 1);
                    }
                  }}
                  colorScheme={step >= 2 ? "blue" : "green"}
                >
                  {step >= 2 ? "Submit" : "Next"}
                </Button>
              </Flex>
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stepper index={activeStep} hidden={window.innerWidth<=800}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <Box flexShrink="0">
                    <StepTitle>{step.title}</StepTitle>
                  </Box>

                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
            {step == 0 ? (
              <Besic setFinalData={setFinalData} finalData={finalData} />
            ) : step == 1 ? (
              <Description
                setFinalData={setFinalData}
                finalData={finalData}
                initImage={initImage}
              />
            ) : (
              <Dates setFinalData={setFinalData} finalData={finalData} />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isCentered size={"ct"} isOpen={isEditOpen} onClose={onEditClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Box>
              <Text>Edit Product</Text>
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <EditProduct onEditClose={onEditClose} toast={toast} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isCentered size={"lg"} isOpen={isLoginOpen} onClose={onLoginClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Login onLoginClose={onLoginClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        size={"xl"}
        isCentered
        isOpen={isProgressOpen}
        onClose={onProgressClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Log Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {stages &&
              stages.map((res) => {
                if (res.stage == "product modified") {
                  return (
                    <Box>
                      <Text>
                        <Text as={"span"} fontWeight={"bold"}>
                          {res.updateBy == undefined
                            ? "System"
                            : capitalizeWords(res.updateBy)}
                        </Text>{" "}
                        <Text as={"span"} fontWeight={"bold"}>
                          Updated The Product
                        </Text>{" "}
                        on{" "}
                        <Text as={"span"} fontWeight={"bold"}>
                          {res.date}
                        </Text>{" "}
                        at{" "}
                        <Text as={"span"} fontWeight={"bold"}>
                          {res.time || "00"}
                        </Text>{" "}
                        {res.stage == "shipping booked" ||
                        res.stage == "update shipping" ? (
                          <Text as={"span"}>
                            With AWB No.{" "}
                            <Text as={"span"} fontWeight={"bold"}>
                              {res.awb}
                            </Text>{" "}
                            and Courier is{" "}
                            <Text as={"span"} fontWeight={"bold"}>
                              {" "}
                              {capitalizeWords(res.courier)}
                            </Text>
                          </Text>
                        ) : null}
                        .
                      </Text>
                    </Box>
                  );
                }
                if (
                  res.stage == "partial payment" ||
                  res.stage == "full payment"
                ) {
                  return (
                    <Box>
                      <Text>
                        <Text as={"span"} fontWeight={"bold"}>
                          {res.updateBy == undefined
                            ? "System"
                            : capitalizeWords(res.updateBy)}
                        </Text>{" "}
                        <Text
                          as={"span"}
                          fontWeight={"bold"}
                          color={
                            res.request == "pending"
                              ? "orange"
                              : res.request == "accept"
                              ? "green"
                              : "red"
                          }
                        >
                          {res.request == "pending"
                            ? "Requested"
                            : res.request == "accept"
                            ? "Accepted"
                            : "Rejected"}
                        </Text>{" "}
                        <Text as={"span"} fontWeight={"bold"}>
                          {capitalizeWords(res.stage)}
                        </Text>{" "}
                        on{" "}
                        <Text as={"span"} fontWeight={"bold"}>
                          {res.date}
                        </Text>{" "}
                        at{" "}
                        <Text as={"span"} fontWeight={"bold"}>
                          {res.time || "00"}
                        </Text>{" "}
                        {res.stage == "shipping booked" ||
                        res.stage == "update shipping" ? (
                          <Text as={"span"}>
                            With AWB No.{" "}
                            <Text as={"span"} fontWeight={"bold"}>
                              {res.awb}
                            </Text>{" "}
                            and Courier is{" "}
                            <Text as={"span"} fontWeight={"bold"}>
                              {" "}
                              {capitalizeWords(res.courier)}
                            </Text>
                          </Text>
                        ) : null}
                        .
                      </Text>
                    </Box>
                  );
                }
                return (
                  <Box>
                    <Text>
                      <Text as={"span"} fontWeight={"bold"}>
                        {res.updateBy == undefined
                          ? "System"
                          : capitalizeWords(res.updateBy)}
                      </Text>{" "}
                      updated status to{" "}
                      <Text as={"span"} fontWeight={"bold"}>
                        {capitalizeWords(res.stage)}
                      </Text>{" "}
                      {res.details != undefined ? (
                        <Text as={"span"} fontWeight={"bold"}>
                          By {capitalizeWords(res.details)}
                        </Text>
                      ) : null}{" "}
                      {res.stage == "hold" ? (
                        <Text as={"span"} fontWeight={"bold"}>
                          due to {capitalizeWords(res.reason || "")}
                        </Text>
                      ) : null}{" "}
                      on{" "}
                      <Text as={"span"} fontWeight={"bold"}>
                        {res.date}
                      </Text>{" "}
                      at{" "}
                      <Text as={"span"} fontWeight={"bold"}>
                        {res.time || "00"}
                      </Text>{" "}
                      {res.stage == "shipping booked" ||
                      res.stage == "update shipping" ? (
                        <Text as={"span"}>
                          With AWB No.{" "}
                          <Text as={"span"} fontWeight={"bold"}>
                            {res.awb}
                          </Text>{" "}
                          and Courier is{" "}
                          <Text as={"span"} fontWeight={"bold"}>
                            {" "}
                            {capitalizeWords(res.courier)}
                          </Text>
                        </Text>
                      ) : null}
                      .
                    </Text>
                  </Box>
                );
              })}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isCentered
        size={"xl"}
        isOpen={isInsertProductOpen}
        onClose={onInsertProductClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Insert Product</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <InsertProduct onInsertProductClose={onInsertProductClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <AlertDialog
        motionPreset="slideInBottom"
        onClose={onDeleteAlertClose}
        isOpen={isDeleteAlertOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogCloseButton />
          <AlertDialogBody mt={"20px"}>
            <Text fontSize={"18px"} align={"center"} fontWeight={"bold"}>
              Delete Order
            </Text>
            Are you sure you want to delete your selected order?
            <Text align={"center"}>You can't undo this action</Text>
            <Box
              borderLeft={"5px solid #f35425"}
              borderRadius={"5px"}
              mt={"20px"}
              bgColor={"#ffe8d9"}
              w={"100%"}
            >
              <Flex p={"10px 20px"} pr={"0px"} gap={"10px"}>
                <WarningTwoIcon color={"#f35425"} fontSize={"20px"} />
                <Flex direction={"column"} mt={"-4px"}>
                  <Text fontWeight={"bold"} color={"#a64b3e"}>
                    Warning
                  </Text>
                  <Text color={"#f35425"} fontWeight={"bold"}>
                    By Deleting this order you will delete all products.
                  </Text>
                </Flex>
              </Flex>
            </Box>
            <Flex mt={"20px"} w={"100%"}>
              <Button
                w={"49%"}
                bgColor="#e12d39"
                color={"white"}
                _hover={{ bgColor: "#e12d35" }}
                onClick={() => {
                  handleDeleteOrder();
                  onDeleteAlertClose();
                }}
              >
                Delete Order
              </Button>
              <Button
                w={"49%"}
                onClick={onDeleteAlertClose}
                bgColor="#627d98"
                color={"white"}
                ml={3}
              >
                Cancel
              </Button>
            </Flex>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog
        motionPreset="slideInBottom"
        onClose={onShippingAlertClose}
        isOpen={isShippingAlertOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Book Shipping</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Select
              ref={courier}
              onChange={(e) => {
                setTempCourier(e.target.value);
              }}
            >
              <option hidden>Select Courier</option>
              {courier_partner.map((ele) => {
                return (
                  <option key={ele} value={ele.toLocaleLowerCase()}>
                    {ele}
                  </option>
                );
              })}
            </Select>
            {tempCourier == "transport" ? (
              <Textarea
                ref={awb}
                mt={"20px"}
                placeholder="Enter AWB No"
                resize={"none"}
              />
            ) : (
              <Input ref={awb} mt={"20px"} placeholder="Enter AWB No" />
            )}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              colorScheme="green"
              onClick={() => {
                handleUpdateShipping();
              }}
            >
              Ok
            </Button>
            <Button onClick={onShippingAlertClose} colorScheme="red" ml={3}>
              Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog
        motionPreset="slideInBottom"
        onClose={onDeleteProductClose}
        isOpen={isDeleteProductOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Delete Product</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody></AlertDialogBody>
          <AlertDialogFooter>
            <Button
              colorScheme="green"
              onClick={() => {
                handleUpdateShipping();
              }}
            >
              Ok
            </Button>
            <Button onClick={onShippingAlertClose} colorScheme="red" ml={3}>
              Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog
        motionPreset="slideInBottom"
        onClose={onHoldAlertClose}
        isOpen={isHoldgAlertOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Hold Product</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Select
              onChange={(e) => {
                dispatch(holdUpdate(e.target.value));
              }}
            >
              {hold_list.map(({ name, hidden }) => {
                return (
                  <option
                    key={name}
                    hidden={hidden == true ? true : false}
                    value={name.toLocaleLowerCase()}
                  >
                    {name}
                  </option>
                );
              })}
            </Select>
            <Textarea
              ref={holdRef}
              hidden={holdValue !== "others"}
              mt={"10px"}
              resize={"none"}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              colorScheme="green"
              onClick={() => {
                handleHold();
              }}
            >
              Ok
            </Button>
            <Button onClick={onShippingAlertClose} colorScheme="red" ml={3}>
              Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog
        motionPreset="slideInBottom"
        onClose={onUpdateAllAlertClose}
        isOpen={isUpdateAllAlertOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Universal Shipping</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Select
              ref={courier}
              onChange={(e) => {
                setTempCourier(e.target.value);
              }}
            >
              <option hidden>Select Courier</option>
              {courier_partner.map((ele) => {
                return (
                  <option key={ele} value={ele.toLocaleLowerCase()}>
                    {ele}
                  </option>
                );
              })}
            </Select>
            {tempCourier == "transport" ? (
              <Textarea
                ref={awb}
                mt={"20px"}
                placeholder="Enter AWB No"
                resize={"none"}
              />
            ) : (
              <Input ref={awb} mt={"20px"} placeholder="Enter AWB No" />
            )}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              colorScheme="green"
              onClick={() => {
                UpdateAllShipping();
              }}
            >
              Submit
            </Button>
            <Button onClick={onShippingAlertClose} colorScheme="red" ml={3}>
              Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog
        motionPreset="slideInBottom"
        onClose={onSelfProductClose}
        isOpen={isSelfProductOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Party Details</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Textarea ref={pickupRef} placeholder="Enter Party Details" />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              colorScheme="green"
              onClick={() => {
                handlePickupProduct();
                onSelfProductClose();
              }}
            >
              Submit
            </Button>
            <Button onClick={onSelfProductClose} colorScheme="red" ml={3}>
              Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Drawer
        placement={"left"}
        onClose={onMobileNavClose}
        isOpen={isMobileNavOpen}
      >
        <DrawerOverlay />

        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">Production Sheet</DrawerHeader>
          <DrawerBody>
            <HomeMenuButtons />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Modal isCentered isOpen={isCancelOpen} onClose={onCancelClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reason to Cancel</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              ref={cancelRef}
              resize={"none"}
              placeholder="Enter Reason"
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" mr={3} onClick={handleCancel}>
              Submit
            </Button>
            <Button colorScheme="red" onClick={onCancelClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box
        p={"10px"}
        m={"0px"}
        zIndex={1000}
        position={"sticky"}
        top={0}
        w={"100%"}
        bgColor={"#f7fafa"}
      >
        <Flex justifyContent={"space-between"}>
          {window.innerWidth <= 800 ? (
            <Flex gap={"6px"}>
              <MobileNav draweropen={onMobileNavOpen} />
              <Refresh refresh={fetchData} />
            </Flex>
          ) : (
            <></>
          )}
          <Image alt="logo" src={logo} h={"40px"} />
          {window.innerWidth <= 800 ? (
            <></>
          ) : (
            <HomeMenuButtons tabIndex={tabIndex} />
          )}
        </Flex>
        <Flex
          justifyContent={"space-between"}
          direction={["column", "column", "row", "row"]}
        >
          <Box maxW={["100%", "100%", "20%", "20%"]}></Box>
          <Box w={["100%", "100%", "55%", "55%"]}>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<Icon as={FaSearch} color="gray.300" />}
              />
              <Input
                onChange={(e) => {
                  handlesearch(e.target.value, 1000);
                }}
                variant="flushed"
                sx={{
                  "&:focus": {
                    boxShadow: "none",
                    borderColor: "transparent",
                  },
                }}
                bgColor="white"
                borderRadius="30px"
                _hover={{}}
                placeholder="Type Something To Search..."
              />
            </InputGroup>
          </Box>

          <Box>
            <Center mt={"5px"}>
              <Text>
                Total Order of this month:
                <Text
                  ml={"5px"}
                  color={"green"}
                  fontWeight={"bold"}
                  as={"span"}
                >
                  {monthorder}
                </Text>
              </Text>
            </Center>
          </Box>
        </Flex>
      </Box>

      <Tabs
        isLazy
        defaultIndex={tabIndex}
        onChange={handleTabChange}
        mb={"70px"}
      >
        <TabList
          pt={"10px"}
          sx={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarWidth: "none",
          }}
          overflowX="auto"
          position={"fixed"}
          top={window.innerWidth <= 800 ? "120px" : "110px"}
          bgColor={"white"}
          w={"100%"}
          zIndex={999}
        >
          <Tab
            onTouchEnd={handleTabChange}
            _selected={{
              fontWeight: "bold",
              color: "blue.500",
              borderBottomWidth: "4px", // Increase the height here
              borderBottomColor: "blue.500", // Adjust color if needed
            }}
          >
            <Text fontSize={"14px"} fontWeight={"bold"}>
              <Text mr={"4px"} as={"span"}>
                Active Order
              </Text>
              <Text as={"span"} fontSize={"13px"}>
                ({orderCount?.active})
              </Text>
            </Text>
          </Tab>
          <Tab
            _selected={{
              fontWeight: "bold",
              color: "blue.500",
              borderBottomWidth: "4px", // Increase the height here
              borderBottomColor: "blue.500", // Adjust color if needed
            }}
          >
            <Text fontSize={"14px"} fontWeight={"bold"}>
              <Text mr={"4px"} as={"span"}>
                Hold Order
              </Text>
              <Text as={"span"} fontSize={"13px"}>
                ({orderCount?.hold})
              </Text>
            </Text>
          </Tab>
          <Tab
            _selected={{
              fontWeight: "bold",
              color: "blue.500",
              borderBottomWidth: "4px", // Increase the height here
              borderBottomColor: "blue.500", // Adjust color if needed
            }}
          >
            <Text fontSize={"14px"} fontWeight={"bold"}>
              <Text mr={"4px"} as={"span"}>
                Queue for Dispatchment
              </Text>
              <Text as={"span"} fontSize={"13px"}>
                ({orderCount?.queuedispatch})
              </Text>
            </Text>
          </Tab>
          <Tab
            _selected={{
              fontWeight: "bold",
              color: "yellow.500",
              borderBottomWidth: "4px", // Increase the height here
              borderBottomColor: "yellow.500", // Adjust color if needed
            }}
          >
            <Text fontSize={"14px"} fontWeight={"bold"}>
              <Text mr={"4px"} as={"span"}>
                Payment Clearence
              </Text>
              <Text as={"span"} fontSize={"13px"}>
                ({orderCount?.payment})
              </Text>
            </Text>
          </Tab>
          <Tab
            _selected={{
              fontWeight: "bold",
              color: "yellow.500",
              borderBottomWidth: "4px", // Increase the height here
              borderBottomColor: "yellow.500", // Adjust color if needed
            }}
          >
            <Text fontSize={"14px"} fontWeight={"bold"}>
              <Text mr={"4px"} as={"span"}>
                Ready for Dispatchment
              </Text>
              <Text as={"span"} fontSize={"13px"}>
                ({orderCount?.readydispatch})
              </Text>
            </Text>
          </Tab>
          <Tab
            _selected={{
              fontWeight: "bold",
              color: "yellow.500",
              borderBottomWidth: "4px", // Increase the height here
              borderBottomColor: "yellow.500", // Adjust color if needed
            }}
          >
            <Text fontSize={"14px"} fontWeight={"bold"}>
              <Text mr={"4px"} as={"span"}>
                Shipped
              </Text>
              <Text as={"span"} fontSize={"13px"}>
                ({orderCount?.shipped})
              </Text>
            </Text>
          </Tab>
          <Tab
            _selected={{
              fontWeight: "bold",
              color: "green.500",
              borderBottomWidth: "4px", // Increase the height here
              borderBottomColor: "green.500", // Adjust color if needed
            }}
          >
            <Text fontSize={"14px"} fontWeight={"bold"}>
              <Text mr={"4px"} as={"span"}>
                Completed Order
              </Text>
              <Text as={"span"} fontSize={"13px"}>
                ({orderCount?.complete})
              </Text>
            </Text>
          </Tab>
          <Tab
            _selected={{
              fontWeight: "bold",
              color: "red.500",
              borderBottomWidth: "4px", // Increase the height here
              borderBottomColor: "red.500", // Adjust color if needed
            }}
          >
            <Text fontSize={"14px"} fontWeight={"bold"}>
              <Text mr={"4px"} as={"span"}>
                Cancelled Order
              </Text>
              <Text as={"span"} fontSize={"13px"}>
                ({orderCount?.cancel})
              </Text>
            </Text>
          </Tab>
        </TabList>

        <TabPanels mt={"25px"}>
          <TabPanel>
            <Suspense fallback={<Loading load={true} />}>
              <TableForm
                handleUpdateOrder={handleUpdateOrder}
                onProgressOpen={onProgressOpen}
                onEditOpen={onEditOpen}
                setProgress={setProgress}
                tabIndex={tabIndex}
                onDeleteProductOpen={onDeleteProductOpen}
                active={true}
              />
            </Suspense>
          </TabPanel>
          <TabPanel>
            <Suspense fallback={<Loading load={true} />}>
              <TableForm
                handleUpdateOrder={handleUpdateOrder}
                onProgressOpen={onProgressOpen}
                onEditOpen={onEditOpen}
                setProgress={setProgress}
                tabIndex={tabIndex}
                onDeleteProductOpen={onDeleteProductOpen}
                hold={true}
              />
            </Suspense>
          </TabPanel>
          <TabPanel>
            <Suspense fallback={<Loading load={true} />}>
              <TableForm
                handleUpdateOrder={handleUpdateOrder}
                onProgressOpen={onProgressOpen}
                onEditOpen={onEditOpen}
                setProgress={setProgress}
                tabIndex={tabIndex}
                onDeleteProductOpen={onDeleteProductOpen}
                queueDispatchment={true}
              />
            </Suspense>
          </TabPanel>
          <TabPanel>
            <Suspense fallback={<Loading load={true} />}>
              <TableForm
                handleUpdateOrder={handleUpdateOrder}
                onProgressOpen={onProgressOpen}
                onEditOpen={onEditOpen}
                setProgress={setProgress}
                tabIndex={tabIndex}
                onDeleteProductOpen={onDeleteProductOpen}
                payment={true}
              />
            </Suspense>
          </TabPanel>
          <TabPanel>
            <Suspense fallback={<Loading load={true} />}>
              <TableForm
                handleUpdateOrder={handleUpdateOrder}
                onProgressOpen={onProgressOpen}
                onEditOpen={onEditOpen}
                setProgress={setProgress}
                tabIndex={tabIndex}
                onDeleteProductOpen={onDeleteProductOpen}
                readyDispatchment={true}
              />
            </Suspense>
          </TabPanel>
          <TabPanel>
            <Suspense fallback={<Loading load={true} />}>
              <TableForm
                handleUpdateOrder={handleUpdateOrder}
                onProgressOpen={onProgressOpen}
                onEditOpen={onEditOpen}
                setProgress={setProgress}
                tabIndex={tabIndex}
                onDeleteProductOpen={onDeleteProductOpen}
                shipped={true}
              />
            </Suspense>
          </TabPanel>
          <TabPanel>
            <Suspense fallback={<Loading load={true} />}>
              <TableForm
                handleUpdateOrder={handleUpdateOrder}
                onProgressOpen={onProgressOpen}
                onEditOpen={onEditOpen}
                setProgress={setProgress}
                tabIndex={tabIndex}
                onDeleteProductOpen={onDeleteProductOpen}
                complete={true}
              />
            </Suspense>
          </TabPanel>
          <TabPanel>
            <Suspense fallback={<Loading load={true} />}>
              <TableForm
                handleUpdateOrder={handleUpdateOrder}
                onProgressOpen={onProgressOpen}
                onEditOpen={onEditOpen}
                setProgress={setProgress}
                tabIndex={tabIndex}
                onDeleteProductOpen={onDeleteProductOpen}
                cancel={true}
              />
            </Suspense>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Box
        w={"100%"}
        p={"0px 15px"}
        position="fixed"
        bottom="0"
        left="0"
        bgColor="#f0f0f0"
        zIndex="docked"
      >
        <Flex gap={"10px"} pt={"10px"} pb={"10px"}>
          <Center>
            <Select
              value={searchParams.get("year")}
              ref={yearRef}
              border={"1px solid black"}
              h={"35px"}
              onChange={(e) => {
                setParams("year", e.target.value);
              }}
            >
              <option value="" hidden>
                Year
              </option>
              {filterYears?.map((e) => {
                return <option value={e}>{e}</option>;
              })}
            </Select>
          </Center>
          <Box hidden={window.innerWidth > 800}>
            <Select
              border={"1px solid black"}
              w={"150px"}
              h={"35px"}
              onChange={(e) => {
                if (e.target.value == "all") {
                  searchParams.delete("month");
                  setSearchParams(searchParams);
                } else {
                  setParams("month", e.target.value);
                }
              }}
            >
              <option hidden>Select Month</option>
              <option value={"all"}>All</option>
              {months.map((e) => {
                return (
                  <option value={e.toLowerCase()}>
                    {capitalizeWords(e.toLowerCase())}
                  </option>
                );
              })}
            </Select>
          </Box>
          <Flex
            hidden={window.innerWidth <= 800}
            justifyContent={"space-around"}
            borderRadius="md"
            p={3}
            w={"93%"}
          >
            {months.map((e) => {
              const month = searchParams.get("month");
              return (
                <Box
                  onClick={() => {
                    if (searchParams.get("month") == e.toLowerCase()) {
                      searchParams.delete("month");
                      setSearchParams(searchParams);
                    } else {
                      setParams("month", e.toLowerCase());
                    }
                  }}
                  bgColor={month == e.toLowerCase() ? "green" : "#0079C2"}
                  color={"white"}
                  fontWeight="bold"
                  p={"6px 20px"}
                  cursor={"pointer"}
                  borderRadius={"5px"}
                  _hover={{ bgColor: "green" }}
                >
                  {e}
                </Box>
              );
            })}
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default Home;
