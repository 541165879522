import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Center,
  Text,
} from "@chakra-ui/react";
const DisplayRawMeterials = ({ data, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={window.innerWidth<=800?"full":"ct"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Used Raw Meterials</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <table>
            <thead>
              <tr>
                <th>Code</th>
                <th>Name</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {data?.map(({ code, name, qty, unit }) => {
                return (
                  <tr>
                    <td>
                      <Center>
                        <Text as={"span"}>
                            <Text hidden={window.innerWidth>800} as={"span"} mr={"5px"}>Code :</Text>
                            <Text as={"span"}>{code}</Text>
                        </Text>
                      </Center>
                    </td>
                    <td>
                      <Center>
                      <Text as={"span"}>
                            <Text hidden={window.innerWidth>800} as={"span"} mr={"5px"}>Name :</Text>
                            <Text as={"span"}>{name}</Text>
                        </Text>
                      </Center>
                    </td>
                    <td>
                      <Center>
                      <Text as={"span"}>
                            <Text hidden={window.innerWidth>800} as={"span"} mr={"5px"}>Quantity :</Text>
                            <Text as={"span"}>{qty} {unit.split("- ")[1]}</Text>
                        </Text>
                      </Center>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DisplayRawMeterials;
