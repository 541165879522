import axios from "axios";
import * as types from "./ActionTypes";
export const signin =
  (payload, toast, onClose, remember) => async (dispatch) => {
    dispatch({ type: types.SIGNIN_REQUEST });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/login`,
        payload
      );
      dispatch({ type: types.SIGNIN_SUCCESS, payload: res.data });
      if (remember.current.checked) {
        localStorage.setItem(
          "login",
          JSON.stringify({ email: payload.email, password: payload.password,remember:remember.current.checked })
        );
      } else {
        localStorage.removeItem("login");
      }
      onClose();
      toast({
        title: res.data.msg,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      dispatch({ type: types.SIGNIN_FAILURE });

      toast({
        title: err.response.data.msg,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
export const logout = (toast) => (dispatch) => {
  try {
    dispatch({ type: types.SIGNOUT_REQUEST });
    dispatch({ type: types.SIGNOUT_SUCCESS });
    toast({
      title: "Account Logout",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  } catch (error) {
    dispatch({ type: types.SIGNOUT_FAILURE });
  }
};
export const purchaseAuth=(pass)=>(dispatch)=>{
  dispatch({ type: types.PURCHASE_AUTH,payload:pass });
}