export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAILURE = "CREATE_ORDER_FAILURE";

export const FETCH_ORDER_REQUEST = "FETCH_ORDER_REQUEST";
export const FETCH_ORDER_SUCCESS = "FETCH_ORDER_SUCCESS";
export const FETCH_ORDER_FAILURE = "FETCH_ORDER_FAILURE";

export const UPDATE_ORDER_REQUEST = "UPDATE_ORDER_REQUEST";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAILURE = "UPDATE_ORDER_FAILURE";

export const DELETE_ORDER_REQUEST = "DELETE_ORDER_REQUEST";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_FAILURE = "DELETE_ORDER_FAILURE";

export const UPDATE_CHECK_REQUEST = "UPDATE_CHECK_REQUEST";
export const UPDATE_CHECK_SUCCESS = "UPDATE_CHECK_SUCCESS";

export const UPDATE_EDIT_SUCCESS = "UPDATE_EDIT_SUCCESS";

export const UPDATE_HOLD_SUCCESS = "UPDATE_HOLD_SUCCESS";

export const UPDATE_SELF_PICKUP_SUCCESS = "UPDATE_SELF_PICKUP_SUCCESS";

export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";

export const TAB_NUMBER_SUCCESS = "TAB_NUMBER_SUCCESS";

export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_GENERATED_TIME = "GET_GENERATED_TIME";

export const GENERATE_REPORT_REQUEST = "GENERATE_REPORT_REQUEST";
export const GENERATE_REPORT_SUCCESS = "GENERATE_REPORT_SUCCESS";
export const GENERATE_REPORT_FAILURE = "GENERATE_REPORT_FAILURE";

export const FILTER_YEAR_SUCCESS = "FILTER_YEAR_SUCCESS";
export const LOADER_UPDATE = "LOADER_UPDATE";

export const SEARCH_FILTER_REQUEST = "SEARCH_FILTER_REQUEST";
export const SEARCH_FILTER_SUCCESS = "SEARCH_FILTER_SUCCESS";
export const SEARCH_FILTER_FAILURE = "SEARCH_FILTER_FAILURE";

export const ALL_USER_REQUEST = "ALL_USER_REQUEST";
export const ALL_USER_SUCCESS = "ALL_USER_SUCCESS";
export const ALL_USER_FAILURE = "ALL_USER_FAILURE";

export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";

export const CLIENT_FEEDBACK_REQUEST = "CLIENT_FEEDBACK_REQUEST";
export const CLIENT_FEEDBACK_SUCCESS = "CLIENT_FEEDBACK_SUCCESS";
export const CLIENT_FEEDBACK_FAILURE = "CLIENT_FEEDBACK_FAILURE";

export const CREATE_COUPON_REQUEST = "CREATE_COUPON_REQUEST";
export const CREATE_COUPON_SUCCESS = "CREATE_COUPON_SUCCESS";
export const CREATE_COUPON_FAILURE = "CREATE_COUPON_FAILURE";