import React from "react";
import {
  Box,
  Center,
  Flex,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const CustomSlider = ({ data }) => {
  const tabIndex = useSelector((store) => store.app.tabIndex);
  return (
    <Box>
      <Flex gap={"10px"} ml={"10px"} mb={"10px"}>
        {data?.map((imgs, i) => {
          const url = `${process.env.REACT_APP_BACKEND_URL}/images/${imgs}`;
          if (i > 3) {
            return <></>;
          }
          return (
            <Box
              key={i}
              w={"max-content"}
            >
              <Center>
                <Popover>
                  <PopoverTrigger>
                    <Image textAlign={"center"} maxW={"100px"} maxH={"100px"} src={url} alt={imgs} />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>
                      <Link to={url} target="_blank">
                        <Image src={url} alt={imgs} />
                      </Link>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Center>
            </Box>
          );
        })}
      </Flex>
      {data.length > 4 ? (
        <Flex gap={"10px"} ml={"10px"} mb={"10px"}>
          {data?.map((imgs, i) => {
            const url = `${process.env.REACT_APP_BACKEND_URL}/images/${imgs}`;
            if (i <= 3) {
              return <></>;
            }
            return (
              <Box
                key={i}
                p={"0px"}
                m={"0px"}
                w={"max-content"}
              >
                <Center>
                  <Popover>
                    <PopoverTrigger>
                      <Image
                        maxW={"100px"}
                        maxH={"100px"}
                        src={url}
                        alt={imgs}
                      />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverBody>
                        <Link to={url} target="_blank">
                          <Image src={url} alt={imgs} />
                        </Link>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Center>
              </Box>
            );
          })}
        </Flex>
      ) : null}
    </Box>
  );
};

export default CustomSlider;
