import AllRoutes from "./Router/AllRoutes";
import "./index.css"
function App() {
  return (
    <div>
      <AllRoutes />
    </div>
  );
}

export default App;
