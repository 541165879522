import * as types from "./ActionTypes";
const initialState = {
  tabIndex: 0,
  orderList: [],
  userList: [],
  orderCount: {},
  monthorder: 0,
  isLoading: false,
  isGenerateReportLoading: false,
  isError: false,
  notification:false,
  hold: "",
  filteryear: [],
  clientFeedback:[],
  editProduct: {},
  selfpickup: {},
  dataList: {},
  reportData: [],
  reportCount: {
    active: 0,
    hold: 0,
    shipped: 0,
    delivered: 0,
    deleted: 0,
    total: 0,
    products: 0,
  },
  lastgenerated: "",
};

export const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.CREATE_ORDER_REQUEST: {
      return { ...state, isLoading: true, isError: false };
    }
    case types.CREATE_ORDER_SUCCESS: {
      return {
        ...state,
        // orderList: [...state.orderList, ...state.orderList],
        isLoading: false,
        isError: false,
      };
    }
    case types.CREATE_ORDER_FAILURE: {
      return { ...state, isLoading: false, isError: true };
    }
    case types.FETCH_ORDER_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.FETCH_ORDER_SUCCESS: {
      return {
        ...state,
        orderList: payload.data,
        orderCount: payload.count,
        monthorder: payload.monthCount,
        isLoading: false,
        isError: false,
      };
    }
    case types.FETCH_ORDER_FAILURE: {
      return { ...state, orderList: [], isLoading: false, isError: true };
    }
    case types.UPDATE_ORDER_REQUEST: {
      return { ...state, isLoading: true, isError: false };
    }
    case types.UPDATE_ORDER_SUCCESS: {
      return { ...state, isLoading: false, isError: false };
    }
    case types.UPDATE_ORDER_FAILURE: {
      return { ...state, isLoading: false, isError: true };
    }
    case types.DELETE_ORDER_REQUEST: {
      return { ...state, isLoading: true, isError: false };
    }
    case types.DELETE_ORDER_SUCCESS: {
      return { ...state, orderList: payload, isLoading: false, isError: false };
    }
    case types.DELETE_ORDER_FAILURE: {
      return { ...state, isLoading: false };
    }
    case types.UPDATE_CHECK_REQUEST: {
      return { ...state, isLoading: true, isError: false };
    }
    case types.UPDATE_CHECK_SUCCESS: {
      return {
        ...state,
        orderList: state.orderList.map((item) =>
          item.id === payload ? { ...item, checked: !item.checked } : item
        ),
        isLoading: false,
        isError: false,
      };
    }
    case types.UPDATE_EDIT_SUCCESS: {
      return { ...state, editProduct: payload };
    }
    case types.UPDATE_SELF_PICKUP_SUCCESS: {
      return { ...state, selfpickup: payload };
    }
    case types.UPDATE_HOLD_SUCCESS: {
      return { ...state, hold: payload };
    }
    case types.GET_USER_DATA_SUCCESS: {
      return { ...state, dataList: payload };
    }
    case types.TAB_NUMBER_SUCCESS: {
      return { ...state, tabIndex: payload };
    }
    case types.GET_REPORT_SUCCESS: {
      return {
        ...state,
        reportData: payload.data,
        reportCount: payload.count,
        isLoading: false,
      };
    }
    case types.GET_GENERATED_TIME: {
      return { ...state, lastgenerated: payload };
    }
    case types.GENERATE_REPORT_REQUEST: {
      return { ...state, isGenerateReportLoading: true };
    }
    case types.GENERATE_REPORT_SUCCESS: {
      return { ...state, isGenerateReportLoading: false };
    }
    case types.GENERATE_REPORT_FAILURE: {
      return { ...state, isGenerateReportLoading: false };
    }
    case types.FILTER_YEAR_SUCCESS: {
      return { ...state, filteryear: payload };
    }
    case types.LOADER_UPDATE: {
      return { ...state, isLoading: payload };
    }
    case types.SEARCH_FILTER_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.SEARCH_FILTER_SUCCESS: {
      return {
        ...state,
        orderList: payload.data,
        orderCount: payload.count,
        isLoading: false,
        isError: false,
      };
    }
    case types.SEARCH_FILTER_FAILURE: {
      return { ...state, orderList: [], isLoading: false, isError: true };
    }
    case types.ALL_USER_REQUEST: {
      return {
        ...state,
        userList: [],
      };
    }
    case types.ALL_USER_SUCCESS: {
      return {
        ...state,
        userList: payload,
      };
    }
    case types.ALL_USER_FAILURE: {
      return {
        ...state,
        userList: [],
      };
    }
    case types.CLIENT_FEEDBACK_REQUEST: {
      return {
        ...state,
        isLoading:true,
        clientFeedback: [],
      };
    }
    case types.CLIENT_FEEDBACK_SUCCESS: {
      return {
        ...state,
        isLoading:false,
        clientFeedback: payload,
      };
    }
    case types.CLIENT_FEEDBACK_FAILURE: {
      return {
        ...state,
        isLoading:false,
        isError:true,
        clientFeedback: [],
      };
    }
    case types.CREATE_COUPON_REQUEST: {
      return {
        ...state,
        isLoading:true,
      };
    }
    case types.CREATE_COUPON_SUCCESS: {
      return {
        ...state,
        isLoading:false,
      };
    }
    case types.CREATE_COUPON_FAILURE: {
      return {
        ...state,
        isLoading:false,
        isError:true,
      };
    }
    case types.UPDATE_NOTIFICATION: {
      return {
        ...state,
        notification:payload
      };
    }
    default:
      return state;
  }
};
