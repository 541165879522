import {
  Box,
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "../Resources/logo.png";
import { useNavigate, useParams } from "react-router";
import { logout } from "../Redux/AuthReducer/Action";
import CustomSlider from "../Component/CustomSlider";
import { capitalizeWords } from "../Component/capitalWords";
import axios from "axios";
const OrderReport = () => {
  let { id } = useParams();
  const [productEdit, setProductEdit] = useState([]);
  const user = useSelector((store) => store.auth.user);
  const token = useSelector((store) => store.auth.token);
  const production_sheet = useSelector((store) => store.app.orderList).filter(
    (e) => e.checked == true
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/sheet/orderreport/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setProductEdit(res.data.data);
      });
  }, []);
  return (
    <Box
      m={["0px 10px", "0px 10px", "0px 10px", "0px 100px"]}
      p={"10px"}
      bgColor={"#FAF9F6"}
    >
      <Flex justifyContent={"space-between"}>
        <Image h={"40px"} src={logo} alt="logo" />
        <Menu hidden={user.first_name == undefined} placement="bottom-end">
          <MenuButton as={Button} hidden={user.first_name == undefined}>
            {user.first_name}
          </MenuButton>
          <MenuList>
            <MenuItem
              _hover={{ bgColor: "green", color: "white" }}
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </MenuItem>
            <MenuItem
              _hover={{ bgColor: "red", color: "white" }}
              onClick={handleLogout}
            >
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Box m={"5px 10px"} mt={"3px"}>
        {production_sheet.map((ele, i) => {
          return (
            <Box>
              <Text fontWeight={"bold"}>Order {i + 1}</Text>
              <Flex
                borderBottom={"1px solid black"}
                mb={"10px"}
                borderTop={"1px solid black"}
              >
                <Box w={"50%"} borderRight={"1px solid black"}>
                  <Text>Tracking No: {ele.trackingID}</Text>
                  <Text>
                    Dealer/School: {ele?.orderData?.dealer?.distributor_name}(
                    {ele?.orderData?.dealer?.distributor_code})
                  </Text>
                  <Text>
                    Order Taken By: {ele?.orderData?.orderBy?.biller_name}
                  </Text>
                </Box>
                <Box maxW={"50%"} ml={"10px"}>
                  <Text>Order Date: {ele.dates.orderDate}</Text>
                  <Text>Expected Date: {ele.dates.expectedDate}</Text>
                </Box>
              </Flex>
              {ele.description.map((elem, i) => {
                let update = 0;
                return (
                  <Box
                    borderBottom={
                      i == ele.description.length - 1 ? null : "1px solid black"
                    }
                  >
                    <Text mt={"5px"} fontWeight={"bold"} color={"gray"}>
                      Product {elem.position + 1}
                    </Text>
                    <Flex>
                      <Box w={"50%"} ml={"10px"}>
                        <Text>Title: {elem.title}</Text>
                        <Text whiteSpace={"pre-wrap"} maxW={"90%"}>
                          Description: {elem.description}
                        </Text>
                      </Box>
                      <Box>
                        <CustomSlider data={elem?.image} />
                      </Box>
                    </Flex>
                    <Box ml={"10px"}>
                      <Text color={"gray"} fontWeight={"bold"}>
                        Product History:
                      </Text>
                      <Box ml={"10px"}>
                        {elem.stage &&
                          elem.stage.map((res) => {
                            if (res.stage == "product modified") {
                              return (
                                <Box>
                                  <Text>
                                    <Text as={"span"} fontWeight={"bold"}>
                                      {res.updateBy == undefined
                                        ? "System"
                                        : capitalizeWords(res.updateBy)}
                                    </Text>{" "}
                                    <Text as={"span"} fontWeight={"bold"}>
                                      Updated The Product
                                    </Text>{" "}
                                    on{" "}
                                    <Text as={"span"} fontWeight={"bold"}>
                                      {res.date}
                                    </Text>{" "}
                                    at{" "}
                                    <Text as={"span"} fontWeight={"bold"}>
                                      {res.time || "00"}
                                    </Text>{" "}
                                    {res.stage == "shipping booked" ||
                                    res.stage == "update shipping" ? (
                                      <Text as={"span"}>
                                        With AWB No.{" "}
                                        <Text as={"span"} fontWeight={"bold"}>
                                          {res.awb}
                                        </Text>{" "}
                                        and Courier is{" "}
                                        <Text as={"span"} fontWeight={"bold"}>
                                          {" "}
                                          {capitalizeWords(res.courier)}
                                        </Text>
                                      </Text>
                                    ) : null}
                                    .
                                  </Text>
                                  <Flex direction={"column"}>
                                    <Text
                                      hidden={
                                        res?.oldData?.title ==
                                        res?.newData?.title
                                      }
                                    >
                                      Title:
                                      <Text
                                        mr={"10px"}
                                        ml={"10px"}
                                        as={"span"}
                                        color={"red"}
                                      >
                                        {res?.oldData?.title}
                                      </Text>
                                      To
                                      <Text
                                        mr={"10px"}
                                        ml={"10px"}
                                        as={"span"}
                                        color={"green"}
                                      >
                                        {res?.newData?.title}
                                      </Text>
                                    </Text>
                                    <Text
                                      hidden={
                                        res?.oldData?.description ==
                                        res?.newData?.description
                                      }
                                    >
                                      Description:
                                      <Text
                                        mr={"10px"}
                                        ml={"10px"}
                                        as={"span"}
                                        color={"red"}
                                      >
                                        {res?.oldData?.description}
                                      </Text>
                                      To
                                      <Text
                                        mr={"10px"}
                                        ml={"10px"}
                                        as={"span"}
                                        color={"green"}
                                      >
                                        {res?.newData?.description}
                                      </Text>
                                    </Text>
                                  </Flex>
                                </Box>
                              );
                            }
                            if (
                              res.stage == "partial payment" ||
                              res.stage == "full payment"
                            ) {
                              return (
                                <Box>
                                  <Text>
                                    <Text as={"span"} fontWeight={"bold"}>
                                      {res.updateBy == undefined
                                        ? "System"
                                        : capitalizeWords(res.updateBy)}
                                    </Text>{" "}
                                    <Text
                                      as={"span"}
                                      fontWeight={"bold"}
                                      color={
                                        res.request == "pending"
                                          ? "orange"
                                          : res.request == "accept"
                                          ? "green"
                                          : "red"
                                      }
                                    >
                                      {res.request == "pending"
                                        ? "Requested"
                                        : res.request == "accept"
                                        ? "Accepted"
                                        : "Rejected"}
                                    </Text>{" "}
                                    <Text as={"span"} fontWeight={"bold"}>
                                      {capitalizeWords(res.stage)}
                                    </Text>{" "}
                                    on{" "}
                                    <Text as={"span"} fontWeight={"bold"}>
                                      {res.date}
                                    </Text>{" "}
                                    at{" "}
                                    <Text as={"span"} fontWeight={"bold"}>
                                      {res.time || "00"}
                                    </Text>{" "}
                                    {res.stage == "shipping booked" ||
                                    res.stage == "update shipping" ? (
                                      <Text as={"span"}>
                                        With AWB No.{" "}
                                        <Text as={"span"} fontWeight={"bold"}>
                                          {res.awb}
                                        </Text>{" "}
                                        and Courier is{" "}
                                        <Text as={"span"} fontWeight={"bold"}>
                                          {" "}
                                          {capitalizeWords(res.courier)}
                                        </Text>
                                      </Text>
                                    ) : null}
                                    .
                                  </Text>
                                </Box>
                              );
                            }
                            return (
                              <Box>
                                <Text>
                                  <Text as={"span"} fontWeight={"bold"}>
                                    {res.updateBy == undefined
                                      ? "System"
                                      : capitalizeWords(res.updateBy)}
                                  </Text>{" "}
                                  updated status to{" "}
                                  <Text as={"span"} fontWeight={"bold"}>
                                    {capitalizeWords(res.stage)}
                                  </Text>{" "}
                                  {res.details != undefined ? (
                                    <Text as={"span"} fontWeight={"bold"}>
                                      By {capitalizeWords(res.details)}
                                    </Text>
                                  ) : null}{" "}
                                  {res.stage == "hold" ? (
                                    <Text as={"span"} fontWeight={"bold"}>
                                      due to {capitalizeWords(res.reason || "")}
                                    </Text>
                                  ) : null}{" "}
                                  on{" "}
                                  <Text as={"span"} fontWeight={"bold"}>
                                    {res.date}
                                  </Text>{" "}
                                  at{" "}
                                  <Text as={"span"} fontWeight={"bold"}>
                                    {res.time || "00"}
                                  </Text>{" "}
                                  {res.stage == "shipping booked" ||
                                  res.stage == "update shipping" ? (
                                    <Text as={"span"}>
                                      With AWB No.{" "}
                                      <Text as={"span"} fontWeight={"bold"}>
                                        {res.awb}
                                      </Text>{" "}
                                      and Courier is{" "}
                                      <Text as={"span"} fontWeight={"bold"}>
                                        {" "}
                                        {capitalizeWords(res.courier)}
                                      </Text>
                                    </Text>
                                  ) : null}
                                  .
                                </Text>
                              </Box>
                            );
                          })}
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default OrderReport;
