import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  Stack,
  useToast,
  Select,
  Flex,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import coupon from "../Resources/coupon_blank.jpg";
import { useDispatch, useSelector } from "react-redux";
import { postCouponDetails } from "../Redux/AppReducer/Action";

const CouponGenerate = ({ isCouponGenerateOpen, onCouponGenerateClose }) => {
  const user = useSelector((store) => store.auth.user);
  const token = useSelector((store) => store.auth.token);
  const userdata = useSelector((store) => store.app.orderList);
  const dispatch = useDispatch();
  const filterCheckedData = userdata.filter((e) => e.checked)[0];
  const canvasRef = useRef(null);
  const now = new Date();
  const day = now.getDate();
  const month = now.getMonth() + 1;
  const year = now.getFullYear();
  const formattedDate = `${day.toString().padStart(2, "0")}-${month
    .toString()
    .padStart(2, "0")}-${year}`;
  const [texts, setTexts] = useState([
    {
      text: filterCheckedData?.trackingID || "",
      x: -370,
      y: 350,
      rotate: 270,
      color: "yellow",
      font: "bold 85px Arial",
    },
    {
      text: "",
      x: 1225,
      y: 310,
      rotate: 0,
      color: "yellow",
      font: "bold 140px Arial",
    },
    {
      text: "",
      x: 740,
      y: 530,
      rotate: 0,
      color: "white",
      font: "30px Sans-Seriff",
    },
    {
      text: formattedDate,
      x: 800,
      y: 430,
      rotate: 0,
      color: "white",
      font: "bold 30px Sans-Seriff",
    },
    {
      text: "",
      x: 1098,
      y: 635,
      rotate: 0,
      color: "white",
      font: "30px Sans-Seriff",
    },
    {
      text: filterCheckedData?.orderData?.dealer?.distributor_name,
      x: 1000,
      y:
        filterCheckedData?.orderData?.dealer?.distributor_name?.split("")
          ?.length > 27
          ? 50
          : 75,
      rotate: 0,
      color: "yellow",
      font: "bold 50px Sans-Seriff",
    },
  ]);
  const [image, setImage] = useState(coupon);
  const toast = useToast();
  const drawTextOnImage = () => {
    const canvas = canvasRef.current;
    if (!canvas) return; // Ensure canvas is not null
    const ctx = canvas.getContext("2d");
    if (!ctx) return; // Ensure context is not null
    const img = new Image();

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      texts.forEach(
        (
          {
            text,
            x,
            y,
            rotate = 0,
            font = "bold 50px Sans-Serif",
            color = "white",
          },
          i
        ) => {
          ctx.save();
          ctx.rotate((rotate * Math.PI) / 180);
          ctx.font = font;
          ctx.fillStyle = color;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          if (text?.length > 27) {
            const splittedText = [];
            let temp = "";
            text.split("").forEach((ele) => {
              if (temp.length === 27) {
                splittedText.push(temp);
                temp = ele;
              } else {
                temp += ele;
              }
            });
            splittedText.push(temp);
            splittedText.forEach((ele, i) => {
              ctx.fillText(ele, x, Number(y) + i * 50);
            });
          } else {
            if (i == 2) {
              ctx.fillText(text <= 9 ? `0${text}` : text, x, y);
            } else if (i == texts.length - 2) {
              ctx.fillText(`${text}/- or above`, x, y);
            } else {
              ctx.fillText(text, x, y);
            }
          }
          ctx.restore(); // Restore state
        }
      );
    };

    img.src = image;
  };
  useEffect(() => {
    drawTextOnImage();
  }, [texts]);
  const handleAddText = (value, pos) => {
    const temp = [...texts];
    temp[pos].text = value;
    setTexts(temp);
    drawTextOnImage();
  };

  const handleDownload = () => {
    const blankFilter = texts.filter(
      (e) => e.text == "" || e.text == undefined
    );
    if (blankFilter.length > 0) {
      toast({
        title: "Field Missing",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const date = new Date(texts[3].text.split("-").reverse().join("/"));
    const payload = {
      trackingID: texts[0].text,
      couponValue: texts[1].text,
      timesOfUse: texts[2].text,
      issueDate: texts[3].text,
      minOrderValue: texts[4].text,
      customerName: texts[5].text,
      generatedBy: user.first_name,
      expDate: date
        .toISOString(date.setFullYear(date.getFullYear() + 1))
        .split("T")[0]
        .split("-")
        .reverse()
        .join("-"),
    };
    dispatch(postCouponDetails(payload, token));
    const canvas = canvasRef.current;
    if (!canvas) return;
    const link = document.createElement("a");
    link.href = canvas.toDataURL("image/png");
    link.download = texts[0].text;
    link.click();
  };

  return (
    <Modal
      isCentered
      isOpen={isCouponGenerateOpen}
      onClose={onCouponGenerateClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Generate Coupon</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <Input disabled value={texts[0].text} placeholder="Tracking ID" />
            <Input
              disabled
              value={filterCheckedData?.orderData?.dealer?.distributor_name}
              placeholder="Name"
            />
            <Flex gap={"10px"}>
              <Input
                value={texts[1].text}
                onChange={(e) => handleAddText(e.target.value, 1)}
                placeholder="Discount Amount"
              />
              <Select
                onChange={(e) => {
                  let value = texts[1].text;
                  if (value[value.length - 1] == "%") {
                    value = value.replace("%", "");
                  }
                  if (value[0] == "₹") {
                    value = value.replace("₹", "");
                  }
                  if (e.target.value == "%") {
                    value += "%";
                  } else {
                    value = `₹${value}`;
                  }
                  handleAddText(value, 1);
                }}
              >
                <option hidden>Discount Type</option>
                <option value={"₹"}>₹</option>
                <option value={"%"}>%</option>
              </Select>
            </Flex>
            <Input
              type="number"
              value={texts[2].text}
              onChange={(e) => handleAddText(e.target.value, 2)}
              placeholder="Times of use"
            />
            <Input
              type="number"
              value={texts[4].text}
              onChange={(e) => handleAddText(e.target.value, 4)}
              placeholder="Min. purchase value"
            />
            <canvas
              ref={canvasRef}
              style={{ height: "200px", width: "100%" }}
            />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="green" mr={3} onClick={handleDownload}>
            Download Coupon
          </Button>
          <Button colorScheme="red" onClick={onCouponGenerateClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CouponGenerate;
