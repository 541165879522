import {
  Box,
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Redux/AuthReducer/Action";
import { useNavigate } from "react-router";
import logo from "../Resources/logo.png";
import ReportTable from "../Component/Report/ReportTable";
import ReportFilters from "../Component/Report/ReportFilters";
import ReportOverview from "../Component/Report/ReportOverview";
import { generatedTime } from "../Redux/AppReducer/Action";
const Report = () => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.auth.user);
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logout());
  };
  useEffect(() => {
    dispatch(generatedTime());
  }, []);
  return (
    <Box p={"20px"}>
      <Box bgColor={"#fafafa"} p={"10px 2px"} position={"sticky"} top={0} zIndex={9999999999} w={"100%"}>
       <Flex justifyContent={"space-between"}>
        <Image h={"40px"} src={logo} alt="logo" />
        <Menu
          hidden={user.first_name == undefined}
          placement="bottom-end"
        >
          <MenuButton as={Button} hidden={user.first_name == undefined}>
            {user.first_name}
          </MenuButton>
          <MenuList>
            <MenuItem
              _hover={{ bgColor: "green", color: "white" }}
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </MenuItem>
            <MenuItem
              _hover={{ bgColor: "red", color: "white" }}
              onClick={handleLogout}
            >
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <ReportFilters /> 
      </Box>
      
      <ReportOverview />
      <ReportTable />
    </Box>
  );
};

export default Report;
