import {
  Box,
  Button,
  Flex,
  Input,
  SimpleGrid,
  Table,
  Textarea,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import ImageUploadComponent from "./ImageUploadComponent";
import { useDispatch, useSelector } from "react-redux";
import default_product_image from "../Resources/default_product_image.png";
import {
  fetchProduction,
  updateProductDetails,
} from "../Redux/AppReducer/Action";
const EditProduct = ({ onEditClose, toast }) => {
  const user = useSelector((store) => store.auth.user);
  const checkImageUrl = (url) => {
    let xhr = new XMLHttpRequest();
    xhr.open("HEAD", url, false); // false makes the request synchronous

    try {
      xhr.send();

      if (xhr.status === 200) {
        let contentType = xhr.getResponseHeader("Content-Type");
        if (contentType && contentType.startsWith("image/")) {
          return true;
        } else {
          console.error("Content type is not an image:", contentType);
          return false;
        }
      } else {
        console.error("HTTP request failed with status code:", xhr.status);
        return false;
      }
    } catch (error) {
      console.error("HTTP request failed:", error);
      return false;
    }
  };
  const { description, id, position, orderID } = useSelector(
    (store) => store.app.editProduct
  );
  const token = useSelector((store) => store.auth.token);
  const dispatch = useDispatch();
  let initImage = [
    default_product_image,
    default_product_image,
    default_product_image,
    default_product_image,
    default_product_image,
    default_product_image,
    default_product_image,
    default_product_image,
  ];
  initImage = initImage.map((el, i) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/images/${
      orderID?.split("-")[0]
    }_${position}_${i + 1}.jpg`;
    if (checkImageUrl(url)) {
      return url;
    } else {
      return el;
    }
  });
  const [selectedImage, setSelectedImage] = useState([]);
  const [displayImage, setdisplayImage] = useState(initImage);
  const [deletedImage, setDeletedImage] = useState([]);
  const editTitle = useRef(null);
  const editDescription = useRef(null);
  const handleEditProduct = () => {
    const currentDate = new Date().toISOString().split("T")[0];
    const date = currentDate.split("-").reverse().join("/");
    const newDate = new Date();
    let hours = newDate.getHours();
    const minutes = newDate.getMinutes();
    const seconds = newDate.getSeconds();
    const amPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")} ${amPm}`;
    const formData = new FormData();
    for (let i = 0; i < selectedImage.length; i++) {
      formData.append(
        "myFile",
        selectedImage[i].pic,
        `${orderID.split("-")[0]}_${position}_${selectedImage[i].position}.jpg`
      );
      description[position]?.image?.push(
        `${orderID.split("-")[0]}_${position}_${selectedImage[i].position}.jpg`
      );
    }
    description[position].stage.push({
      updateBy: user.first_name,
      date,
      time: formattedTime,
      stage: "product modified",
      oldData: {
        title: description[position].title,
        description: description[position].description,
      },
      newData: {
        title: editTitle.current.value,
        description: editDescription.current.value,
      },
    });
    description[position].title = editTitle.current.value;
    description[position].description = editDescription.current.value;
    formData.append("data", JSON.stringify(description));
    formData.append("deleteImage", JSON.stringify(deletedImage));
    setSelectedImage([]);
    setdisplayImage([]);
    setDeletedImage([]);
    dispatch(updateProductDetails(formData, id, token)).then(() => {
      toast({
        title: "Product Updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onEditClose();
    });
  };
  const handleImageUpload = (event, pos) => {
    const filterdeleteImage = deletedImage.filter(
      (ele) => ele.position !== pos
    );
    setDeletedImage(filterdeleteImage);
    let pics = selectedImage;
    let blob = [...displayImage];
    if (event.target.files[0] !== undefined) {
      pics[selectedImage.length] = {
        pic: event.target.files[0],
        position: pos + 1,
      };
      blob[pos] = URL.createObjectURL(event.target.files[0]);
      setSelectedImage(pics);
      setdisplayImage(blob);
      event.target.value = "";
    }
  };
  const handleDeleteImage = (e, pos) => {
    e.preventDefault();
    if (displayImage[pos].includes("image")) {
      let temp = [...deletedImage];
      temp.push({ position: pos, image: displayImage[pos] });
      setDeletedImage(temp);
    }
    let pics = selectedImage.filter((ele, i) => i !== pos);
    let blob = [...displayImage];
    URL.revokeObjectURL(blob[pos]);
    blob[pos] = default_product_image;
    setSelectedImage(pics);
    setdisplayImage(blob);
  };
  return (
    <Box mt={"10px"}>
      <Flex direction={"column"}>
        <Input
          ref={editTitle}
          defaultValue={description && description[position].title}
          mt={"10px"}
          placeholder="Title"
        />
        <Flex gap={"10px"}>
          <Textarea
            ref={editDescription}
            w={"50%"}
            mt={"10px"}
            resize={"none"}
            defaultValue={description && description[position].description}
            h={"250px"}
            placeholder="Description"
          />
          <Flex direction={"column"}>
            <SimpleGrid h={"90%"} columns={4} gap={"5px"}>
              <ImageUploadComponent
                position={0}
                handleDeleteImage={handleDeleteImage}
                handleImageUpload={handleImageUpload}
                displayImage={displayImage}
              />
              <ImageUploadComponent
                position={1}
                handleDeleteImage={handleDeleteImage}
                handleImageUpload={handleImageUpload}
                displayImage={displayImage}
              />
              <ImageUploadComponent
                position={2}
                handleDeleteImage={handleDeleteImage}
                handleImageUpload={handleImageUpload}
                displayImage={displayImage}
              />
              <ImageUploadComponent
                position={3}
                handleDeleteImage={handleDeleteImage}
                handleImageUpload={handleImageUpload}
                displayImage={displayImage}
              />
              <ImageUploadComponent
                position={4}
                handleDeleteImage={handleDeleteImage}
                handleImageUpload={handleImageUpload}
                displayImage={displayImage}
              />
              <ImageUploadComponent
                position={5}
                handleDeleteImage={handleDeleteImage}
                handleImageUpload={handleImageUpload}
                displayImage={displayImage}
              />
              <ImageUploadComponent
                position={6}
                handleDeleteImage={handleDeleteImage}
                handleImageUpload={handleImageUpload}
                displayImage={displayImage}
              />
              <ImageUploadComponent
                position={7}
                handleDeleteImage={handleDeleteImage}
                handleImageUpload={handleImageUpload}
                displayImage={displayImage}
              />
            </SimpleGrid>
            <Flex gap={"1%"}>
              <Button
                w={"49%"}
                mt={"10px"}
                colorScheme="green"
                onClick={handleEditProduct}
              >
                Update
              </Button>
              <Button
                w={"49%"}
                mt={"10px"}
                colorScheme="red"
                onClick={onEditClose}
              >
                Cancel
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default EditProduct;
