import * as types from "./ActionTypes";
const initialState = {
  isLoading: false,
  isError: false,
  referenceID: "",
  suppliers: [],
  warehouse: [],
  allPayment: [],
};

export const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.INITIAL_PURCHASE_REQUEST: {
      return {
        ...state,
        referenceID: "",
        suppliers: [],
        warehouse: [],
        isLoading: true,
        isError: false,
      };
    }
    case types.INITIAL_PURCHASE_SUCCESS: {
      return {
        ...state,
        referenceID: payload.ref,
        suppliers: payload.supplier,
        warehouse: payload.warehouse,
        isLoading: false,
        isError: false,
      };
    }
    case types.INITIAL_PURCHASE_FAILURE: {
      return {
        ...state,
        referenceID: "",
        suppliers: [],
        warehouse: [],
        isLoading: false,
        isError: true,
      };
    }
    case types.ALL_PURCHASE_REQUEST: {
      return {
        ...state,
        allPayment: [],
        isLoading: true,
        isError: false,
      };
    }
    case types.ALL_PURCHASE_SUCCESS: {
      return {
        ...state,
        allPayment: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.ALL_PURCHASE_FAILURE: {
      return {
        ...state,
        allPayment: [],
        isLoading: false,
        isError: true,
      };
    }
    case types.UPDATE_REFERENCE_SUCCESS: {
      return {
        ...state,
        referenceID: payload,
      };
    }
    default:
      return state;
  }
};
