import {
  Box,
  Button,
  Center,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { FaBell } from "react-icons/fa";
import { VscBellDot } from "react-icons/vsc";
import { sockets } from "../emitter";
import { updateNotification } from "../Redux/AppReducer/Action";
const ProfileButton = ({ handleLogin }) => {
  const navigate = useNavigate();
  const user = useSelector((store) => store.auth.user);
  const notify = useSelector((store) => store.app.notification);
  const dispatch = useDispatch();
  const [newNoti, setNewNoti] = useState(false);
  const [localS, setLocalS] = useState(
    JSON.parse(localStorage.getItem("notification")) || []
  );
  useEffect(() => {
    localStorage.setItem("notification", JSON.stringify(localS));
  }, [localS]);
  useEffect(() => {
    sockets.on("notification", (data) => {
      const localData =
        JSON.parse(localStorage.getItem("notification")).reverse() || [];
      if (
        localData.length <= 0 ||
        localData[localData.length - 1]?.msg != data?.msg
      ) {
        dispatch(updateNotification(true));
        localData.push(data);
      }
      localStorage.setItem("notification", JSON.stringify(localData.reverse()));
      setLocalS(data);
    });
  }, []);
  return (
    <Flex>
      <Menu
        hidden={user.first_name == undefined}
        placement="bottom-end"
        zIndex={1000}
      >
        <MenuButton
          fontSize={"12px"}
          as={Button}
          hidden={user.first_name == undefined}
        >
          {user.first_name}
        </MenuButton>
        <MenuList fontSize={"14px"}>
          {/* {user.role == "admin" ? (
              <MenuItem
                _hover={{ bgColor: "blue", color: "white" }}
                onClick={() => {
                  onUpdateOpen();
                }}
              >
                User Permissions
              </MenuItem>
            ) : null} */}
          <MenuItem
            _hover={{ bgColor: "green", color: "white" }}
            onClick={() => navigate("/purchase")}
          >
            Add Purchase
          </MenuItem>
          <MenuItem
            _hover={{ bgColor: "green", color: "white" }}
            onClick={() => navigate("/sales")}
          >
            Add Sales
          </MenuItem>
          <MenuItem
            hidden={window.innerWidth <= 1000}
            _hover={{ bgColor: "green", color: "white" }}
            onClick={() => navigate("/products")}
          >
            Products
          </MenuItem>
          <MenuItem
            hidden={window.innerWidth <= 1000}
            _hover={{ bgColor: "green", color: "white" }}
            onClick={() => navigate("/feedback")}
          >
            Client Feedback
          </MenuItem>
          <MenuItem
            _hover={{ bgColor: "red", color: "white" }}
            onClick={handleLogin}
          >
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
      <Popover
        placement="top-end"
        size={"xl"}
        arrowSize={25}
        arrowPadding={5}
        onClose={() => {
          setLocalS((prev) =>
            prev.map((e) => {
              e.read = true;
              return e;
            })
          );
        }}
      >
        <PopoverTrigger>
          <Center
            h={"40px"}
            cursor={"pointer"}
            p={"0px 10px"}
            onClick={() => {
              dispatch(updateNotification(false));
            }}
          >
            {notify ? <VscBellDot /> : <FaBell />}
          </Center>
        </PopoverTrigger>
        <PopoverContent style={{ width: "500px" }}>
          <PopoverArrow />
          <PopoverBody>
            {localS.length <= 0 ? <Text>No Notification</Text> : null}
            <Box
              fontSize={"15px"}
              h={"150px"}
              overflow={"scroll"}
              overflowX={"hidden"}
            >
              {localS.map((e, i) => {
                const trackingPattern = /RMP\d+/;

                const trackingMatch = e.msg.match(trackingPattern);

                if (!trackingMatch) return null;

                const trackingNumber = trackingMatch[0];
                const trackingIndex = e.msg.indexOf(trackingNumber);

                const beforeTracking = e.msg.substring(0, trackingIndex);
                const afterTracking = e.msg.substring(
                  trackingIndex + trackingNumber.length
                );
                return (
                  <Text
                    borderBottom={
                      localS.length - 1 == i ? null : "1px solid black"
                    }
                  >
                    <Text
                      as="span"
                      fontWeight="bold"
                      color="red.500"
                      hidden={e.read}
                    >
                      (NEW)
                    </Text>{" "}
                    {beforeTracking}
                    {trackingMatch && (
                      <Text as="span" fontWeight="bold" color="teal.500">
                        {trackingMatch}
                      </Text>
                    )}
                    {afterTracking} on
                    <Text as="span" fontWeight="bold" color="teal.500">
                      {" "}
                      {e.date}
                    </Text>{" "}
                    at
                    <Text as="span" fontWeight="bold" color="teal.500">
                      {" "}
                      {e.time}
                    </Text>
                  </Text>
                );
              })}
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

export default ProfileButton;
