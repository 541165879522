import {
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const ImagePopOver = ({ url, e }) => {
  return (
    <Popover>
      <PopoverTrigger>
        <Image cursor={"pointer"} h={"50px"} src={url} alt={e} />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Link to={url} target="_blank">
            <Image src={url} alt={e} />
          </Link>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ImagePopOver;
