import {
  Box,
  Center,
  Flex,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ProfileButton from "../Component/ProfileButton";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Redux/AuthReducer/Action";
import logo from "../Resources/logo.png";
import { getClientFeedback } from "../Redux/AppReducer/Action";
const ClientFeedback = () => {
  const dispatch = useDispatch();
  const token = useSelector((store) => store.auth.token);
  const feedbackData = useSelector((store) => store.app.clientFeedback);
  const handleLogout = () => {
    dispatch(logout());
  };
  useEffect(() => {
    dispatch(getClientFeedback(token));
  }, []);
  return (
    <Box p={["10px", "10px", "10px", "10px 100px"]}>
      <Box
        mb={"10px"}
        position={"sticky"}
        top={0}
        bgColor={"#FAF9F6"}
        mt={"-10px"}
        p={"8px 5px"}
      >
        <Flex justifyContent={"space-between"}>
          <Link to={"/"}>
            <Image h={"40px"} src={logo} alt="logo" />
          </Link>
          <Center hidden={window.innerWidth <= 800}>
            <Text fontWeight={"bold"} fontSize={"20px"} textAlign={"center"}>
              Client Feedbacks
            </Text>
          </Center>
          <ProfileButton handleLogin={handleLogout} />
        </Flex>
      </Box>
      <table style={{ width: "100%" }} hidden={feedbackData.length <= 0}>
        <thead>
          <tr>
            <th style={{ width: "20%" }}>Tracking ID</th>
            <th style={{ width: "40%" }}>Client Feedback</th>
            <th border={"1px solid black"}>Date</th>
          </tr>
        </thead>
        <tbody>
          {feedbackData.map((ele) => {
            const date = new Date(ele.created_at);
            const day = String(date.getUTCDate()).padStart(2, "0");
            const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based
            const year = date.getUTCFullYear();
            let hours = date.getUTCHours();
            const minutes = String(date.getUTCMinutes()).padStart(2, "0");
            const seconds = String(date.getUTCSeconds()).padStart(2, "0");

            // Determine AM or PM
            const ampm = hours >= 12 ? "PM" : "AM";

            // Convert hours from 24-hour to 12-hour format
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            const formattedHours = String(hours).padStart(2, "0");

            // Format the date and time as dd/mm/yyyy hh:mm:ss AM/PM
            const newDate = `${day}/${month}/${year} ${formattedHours}:${minutes}:${seconds} ${ampm}`;
            return (
              <tr>
                <td>
                  <Text textAlign={"center"}>{ele.trackingID}</Text>
                </td>
                <td>
                  <Flex direction={"column"} ml={"10px"}>
                    <Text>Rating: {ele.rating}</Text>
                    <Text>{ele.message}</Text>
                    <SimpleGrid
                      ml={"10px"}
                      pb={"10px"}
                      pt={"10px"}
                      columns={[3, 3, 3, 4]}
                      gap={"10px"}
                    >
                      {ele?.feedbackimg?.map((e) => {
                        const url = `${process.env.REACT_APP_BACKEND_URL}/feedbackimage/${e}`;
                        return (
                          <Popover key={e}>
                            <PopoverTrigger>
                              <Image
                                cursor={"pointer"}
                                h={"100px"}
                                maxW={"300px"}
                                src={url}
                                alt={e}
                              />
                            </PopoverTrigger>
                            <PopoverContent>
                              <PopoverArrow />
                              <PopoverCloseButton />
                              <PopoverBody>
                                <Link to={url} target="_blank">
                                  <Image src={url} alt={e} />
                                </Link>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        );
                      })}
                    </SimpleGrid>
                  </Flex>
                </td>
                <td>
                  <Text textAlign={"center"}>{newDate}</Text>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
};

export default ClientFeedback;
