import {
  Box,
  Button,
  Flex,
  Text,
  Center,
  Select,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  MenuDivider,
  Checkbox,
  CheckboxGroup,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import { SlCalender } from "react-icons/sl";
import { getAllData, getReport } from "../../Redux/AppReducer/Action";
import { useDispatch, useSelector } from "react-redux";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { universal_stages } from "../../universal_variable";
import { capitalizeWords } from "../capitalWords";
const ReportFilters = () => {
  const load = useSelector((store) => store.app.isLoading);
  const employees = useSelector((store) => store.app.dataList.Employee);
  const generateLoad = useSelector(
    (store) => store.app.isGenerateReportLoading
  );
  const toast=useToast();
  const preview = useSelector((store) => store.app.reportData);
  const dispatch = useDispatch();
  const [showPicker, setShowPicker] = useState(false);
  const token = useSelector((store) => store.auth.token);
  const [checkOrderBy, setCheckOrderBy] = useState([]);
  const [checkFilterStage, setCheckFilterStage] = useState([]);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  const [mainState, setMainState] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 0),
  });
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    month = month < 10 ? `0${month}` : month.toString();
    day = day < 10 ? `0${day}` : day.toString();
    return `${year}-${month}-${day}`;
  };
  const handleApply = (pdf) => {
    try {
      const data = {
        startdate: formatDate(mainState.startDate),
        enddate: formatDate(mainState.endDate),
      };
      data.orderBy = checkOrderBy;
      data.stages = checkFilterStage;
      dispatch(getReport(data, token, pdf,toast));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (employees == undefined || employees?.length <= 0) {
      dispatch(getAllData("employee"));
    }
  }, []);
  return (
    <Box mb={"10px"}>
      <Flex
        mb={"10px"}
        gap={"10px"}
        direction={["column", "column", "row", "row"]}
      >
        <Flex gap={"5px"}>
          <Flex>
            <Center mt={"5px"} mr={"5px"}>
              Select Date :
            </Center>
            <Flex
              border={"2px solid black"}
              gap={"5px"}
              p={"0px 5px 0px 10px"}
              mt={"5px"}
            >
              <Text fontSize={["14px", "15px", "15px", "15px"]}>
                {" "}
                {mainState.startDate.toDateString()} -{" "}
                {mainState.endDate.toDateString()}
              </Text>
              <Box
                mt={"4px"}
                onClick={() => {
                  const temp = [...state];
                  temp[0].startDate = mainState.startDate;
                  temp[0].endDate = mainState.endDate;
                  setState(temp);
                  setShowPicker(true);
                }}
                cursor={"pointer"}
              >
                <SlCalender color={showPicker ? "green" : "blue"} />
              </Box>
            </Flex>
          </Flex>
          {showPicker && (
            <Box
              backgroundColor="white"
              position={"fixed"}
              top={20}
              border={"0px solid black"}
              left={5}
              borderColor="gray.200"
              padding="4"
              zIndex="1"
              marginTop="4"
              width="320px"
            >
              <DateRange
                onChange={(item) => setState([item.selection])}
                showSelectionPreview={true}
                ranges={state}
                scroll={{ enabled: true }}
                maxDate={addDays(new Date(), 0)}
              />
              <Flex
                pt={"10px"}
                justifyContent={"space-between"}
                w={"120%"}
                backgroundColor={"white"}
              >
                <Button
                  colorScheme="red"
                  onClick={() => {
                    setShowPicker(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="green"
                  isDisabled={
                    state[0].startDate == mainState.startDate &&
                    state[0].endDate == mainState.endDate
                  }
                  onClick={() => {
                    setMainState({
                      startDate: state[0].startDate,
                      endDate: state[0].endDate,
                    });
                    setShowPicker(false);
                  }}
                >
                  Apply
                </Button>
              </Flex>
            </Box>
          )}
        </Flex>
        <Flex gap={"5px"}>
          <Center>Order Taken By:</Center>
          <Menu closeOnSelect={false}>
            <MenuButton
              w={"230px"}
              mt={"2px"}
              rightIcon={<ChevronDownIcon />}
              border={"1px solid #dce3e3"}
              as={Button}
              variant={"outline"}
              colorScheme="transparent"
            >
              <Text
                fontWeight={"normal"}
                overflow={"hidden"}
                textAlign={"left"}
              >
                {checkOrderBy.length <= 0
                  ? "Select Names"
                  : employees
                      .filter((elm) => {
                        if (checkOrderBy.includes(elm.bc_code)) {
                          return true;
                        }
                      })
                      .map((el) => el.biller_name)
                      .join(",")}
              </Text>
            </MenuButton>
            <MenuList minWidth="240px" maxH="500px" overflowY="auto">
              <MenuOptionGroup title="Names" type="checkbox">
                <Flex direction={"column"}>
                  <CheckboxGroup
                    onChange={(e) => {
                      setCheckOrderBy(e);
                    }}
                  >
                    {employees?.map((e) => {
                      return (
                        <Checkbox ml={"10px"} p={"5px"} value={e.bc_code}>
                          {e.biller_name}
                        </Checkbox>
                      );
                    })}
                  </CheckboxGroup>
                </Flex>
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </Flex>
        <Flex gap={"5px"}>
          <Center>Filter By Stage:</Center>
          <Menu closeOnSelect={false}>
            <MenuButton
              w={"230px"}
              mt={"2px"}
              rightIcon={<ChevronDownIcon />}
              border={"1px solid #dce3e3"}
              as={Button}
              variant={"outline"}
              colorScheme="transparent"
            >
              <Text
                fontWeight={"normal"}
                overflow={"hidden"}
                textAlign={"left"}
              >
                {checkFilterStage.length <= 0
                  ? "Select Stages"
                  : checkFilterStage.map((f) => capitalizeWords(f)).join(",")}
              </Text>
            </MenuButton>
            <MenuList minWidth="240px" maxH="500px" overflowY="auto">
              <MenuOptionGroup title="Stages" type="checkbox">
                <Flex direction={"column"}>
                  <CheckboxGroup
                    onChange={(e) => {
                      setCheckFilterStage(e);
                    }}
                  >
                    {universal_stages.map((e) => {
                      return (
                        <Checkbox
                          isDisabled={e.value == "------------"}
                          ml={"10px"}
                          p={"5px"}
                          value={e.value}
                        >
                          {e.name}
                        </Checkbox>
                      );
                    })}
                  </CheckboxGroup>
                </Flex>
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
      <Button
        isLoading={load}
        colorScheme="blue"
        border={"0px solid black"}
        mr={"10px"}
        onClick={() => {
          handleApply(false);
        }}
      >
        <Center>View Report</Center>
      </Button>
      <Button
        isLoading={generateLoad}
        isDisabled={preview.length <= 0}
        colorScheme="blue"
        border={"0px solid black"}
        onClick={() => {
          handleApply(true);
        }}
      >
        <Center>Generate Report</Center>
      </Button>
    </Box>
  );
};

export default ReportFilters;
