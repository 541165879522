import {
  Box,
  Button,
  Center,
  Flex,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiRefresh } from "react-icons/bi";

const TrackTableCard = ({ courier, awb }) => {
  const [status, setStatus] = useState({});
  const [showSkeleton, setShowSkeleton] = useState(false);
const getShippingDetails=()=>{
  try {
    if (courier != "" && courier.toUpperCase() !== "TRANSPORT") {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/ship?awb=${awb}`)
        .then((res) => {
          setShowSkeleton(false);
          setStatus({
            history: res?.data?.[0]?.history?.[0],
            cstatus: res?.data?.[0]?.currentstatus,
            estimatedDate: res?.data?.[0]?.deliveryDate,
          });
        });
    }
  } catch (error) {
    console.log(error)
  }
}
  if (showSkeleton && awb !== "" && courier.toUpperCase() !== "TRANSPORT") {
    return (
      <Stack>
        <Button
          onClick={() => {
            getShippingDetails()
          }}
        >
          Refresh
          <BiRefresh fontSize={"20px"} />
        </Button>
        <Skeleton height="20px" />
        <Skeleton height="60px" mt={"10px"} />
        <Skeleton height="20px" mt={"10px"} />
        <Skeleton height="30px" />
      </Stack>
    );
  }
  return (
    <Box
      mt={"10px"}
      borderTop={"1px solid black"}
      hidden={awb==""}
    >
      <Button m={"10px"} hidden={status?.history!=undefined}
          onClick={() => {
            setShowSkeleton(true);
            getShippingDetails()
          }}
        >
          Shipping Details
        </Button>
      <Box hidden={status?.history == undefined}>
        <Flex>
        <Text mt={"5px"} color={"red"}>
          Current Status
        </Text>
        <Button
          variant={"unstyled"}
          onClick={() => {
            setShowSkeleton(true);
            getShippingDetails()
          }}
        >
          <Box mt={"-5px"}>
            <BiRefresh fontSize={"20px"} />
          </Box>
        </Button>
      </Flex>
      <Text fontWeight={"bold"} whiteSpace={"pre-wrap"} color={"green"}>
        {status?.cstatus}
      </Text>
      <Text
        hidden={
          status?.history?.location == undefined ||
          status?.history?.location == ""
        }
        fontWeight={"bold"}
        whiteSpace={"pre-wrap"}
        color={"green"}
      >
        {status?.history?.location}
      </Text>
      <Text mt={"5px"} color={"red"}>
        Estimated Date
      </Text>
      <Text fontWeight={"bold"} whiteSpace={"pre-wrap"} color={"green"}>
        {status?.estimatedDate}
      </Text>
      </Box>
      
    </Box>
  );
};

export default TrackTableCard;
