import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Input,
  Center,
  Flex,
  Button,
  Stack,
  Text,
  Select,
  Tooltip,
} from "@chakra-ui/react";
import axios from "axios";
import { DeleteIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
const PurchaseTable = ({
  tableData,
  setTableData,
  autoFocus = true,
  hidewearhouse = true,
  warehouses,
  setWarehouses,
}) => {
  const warehouse = useSelector((store) => store.purchase.warehouse);
  const [inputTimeout, setInputTimeout] = useState(null);
  const toast = useToast();
  const searchRef = useRef();
  // useEffect(() => {
  //   if (autoFocus) {
  //     searchRef.current.focus();
  //     setInterval(() => {
  //       if (searchRef.current) {
  //         searchRef.current.focus();
  //       }
  //     }, 5000);
  //   }
  // }, [autoFocus]);

  const handleDelete = (position) => {
    const temp = tableData.filter((e, i) => i != position);
    setTableData(temp);
  };
  const processInput = async () => {
    const inputString = searchRef.current.value;
    const data = inputString.split("X"); // Adjust the delimiter as needed
    if (data[0].trim() == "") {
      return;
    }
    let flag = false;
    const updatedData = tableData.map((ele) => {
      if (ele.code === data[0].trim()) {
        ele.qty++;
        ele.total = ele.price * ele.qty;
        flag = true;
      }
      return ele;
    });

    if (flag) {
      setTableData(updatedData);
      searchRef.current.value = "";
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/purchase/product`,
          { code: data[0].trim(), warehouse: hidewearhouse ? "" : warehouses },
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_PURCHASE_KEY}`,
            },
          }
        )
        .then((productData) => {
          if (data[0].trim() != "" || data[0].trim() != undefined) {
            setTableData([
              ...tableData,
              {
                code: data[0].trim(),
                qty: 1,
                name: productData.data.name,
                price: productData.data.price,
                total: productData.data.price,
                id: productData.data.id,
                availableQty: productData.data.availableQty,
                unit: productData.data.unit,
              },
            ]);
            searchRef.current.value = "";
          }
        })
        .catch((err) => {
          toast({
            title: "Product Not Found",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  };

  const handleKeyDown = (e) => {
    const blockedKey = ["Shift", "Alt", "Backspace"];
    clearTimeout(inputTimeout);
    if (blockedKey.includes(e.key)) {
      return;
    }
    if (e.key === "Enter") {
      processInput();
    } else {
      const timeout = setTimeout(() => {
        processInput();
      }, 3000);
      setInputTimeout(timeout);
    }
  };
  const handleQTYPrice = (field, value, position) => {
    const temp = [...tableData];
    temp[position][field] = value;
    temp[position].total = temp[position].qty * temp[position].price;
    setTableData(temp);
  };
  return (
    <Box textAlign="center" pt={"5px"}>
      <Flex gap={"10px"}>
        <Input
          placeholder="Type/Scan Code Here"
          ref={searchRef}
          mb={"10px"}
          borderRadius={"0px"}
          onKeyDown={handleKeyDown}
        />
        <Tooltip label={"Warehouse Name"} hasArrow placement={"left"}>
          <Box
            h={"40px"}
            pr={"0px"}
            border={"1px solid gray"}
            pt={"6px"}
            pl={"3px"}
            hidden={hidewearhouse}
          >
            <Select
              value={warehouses}
              onChange={(e) => setWarehouses(Number(e.target.value))}
              w={"249px"}
              variant={"unstyled"}
              border={"0px solid gray"}
            >
              <option hidden>Select Warehouse</option>
              {warehouse?.map((e) => {
                return (
                  <option value={e.warehouse_id}>{e.warehouse_name}</option>
                );
              })}
            </Select>
          </Box>
        </Tooltip>
      </Flex>

      <Table variant="simple" mx="auto" w="100%">
        <Thead>
          <Tr>
            <Th>
              <Center>Item ID</Center>
            </Th>
            <Th>
              <Center>Quantity</Center>
            </Th>
            <Th>Item Name</Th>
          </Tr>
        </Thead>
        <Tbody>
          {tableData.map((item, index) => (
            <Tr key={index}>
              <Td>
                <Flex justifyContent={"space-between"} w={"70%"}>
                  <Center>
                    <Box
                      mt={"-5px"}
                      mr={"10px"}
                      cursor={"pointer"}
                      onClick={() => {
                        handleDelete(index);
                      }}
                    >
                      <DeleteIcon color={"red"} />
                    </Box>
                  </Center>
                  <Center>{item.code}</Center>
                </Flex>
              </Td>
              <Td>
                <Stack>
                  <Center>
                    <Input
                      maxW={"100px"}
                      value={item.qty}
                      onChange={(e) => {
                        handleQTYPrice("qty", e.target.value, index);
                      }}
                    />
                  </Center>
                  <Center>
                    <Text color={"#00FF00"}>
                      Available Qty: {item.availableQty}
                    </Text>
                  </Center>
                </Stack>
              </Td>
              <Td>{item.name}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default PurchaseTable;
