import { Box, Button, Center, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { BiRefresh } from "react-icons/bi";
import Loading from "./Loading";
const Refresh = ({ refresh }) => {
  const [rotationDegree, setRotationDegree] = useState(0);
  const [load, setLoad] = useState(false);
  const rotateIcon = () => {
    setLoad(true);
    setRotationDegree(rotationDegree + 360);
  };
  const update = () => {
    rotateIcon();
    setTimeout(() => {
      refresh();
      setLoad(false);
    }, 2000);
  };
  return (
    <Box>
      <Loading load={load} />
      <Button fontSize={"12px"}
        onClick={update}
        colorScheme="cyan"
        color={"white"}
        letterSpacing={"1px"}
        p={"7px"}
      >
        <Center>{window.innerWidth <= 760 ? <></> : <Box>Refresh</Box>}</Center>
        <Center
          transform={`rotate(${rotationDegree}deg)`}
          transition={"transform 2s ease"}
        >
          <BiRefresh fontSize={window.innerWidth <= 760 ? "30px":"15px"} />
        </Center>
      </Button>
    </Box>
  );
};

export default Refresh;
