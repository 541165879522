import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Table,
  Tbody,
  Th,
  Thead,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Heading,
  Text,
  useColorModeValue,
  ModalHeader,
  useDisclosure,
  Image,
  Center,
  useToast,
  useSteps,
  Step,
  StepIndicator,
  StepStatus,
  StepIcon,
  StepNumber,
  StepTitle,
  Stepper,
  StepSeparator,
  Select,
  Textarea,
  Icon,
  SimpleGrid,
  Td,
  Tr,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Tabs,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import React, { useRef, useState, useEffect } from "react";
import default_product_image from "../Resources/default_product_image.png";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { fetchProduction, insertProduct } from "../Redux/AppReducer/Action";

const InsertProduct = ({ onInsertProductClose }) => {
  const dispatch = useDispatch();
  const production_sheet = useSelector((store) => store.app.orderList);
  const token = useSelector((store) => store.auth.token);
  const dummy_sheet = [...production_sheet];
  const filterData = dummy_sheet.filter((ele) => ele.checked == true);
  const dummy_filter = { ...filterData[0] };
  dummy_filter.description = [];
  delete dummy_filter.checked;
  delete dummy_filter.created_at;
  delete dummy_filter.orderData;
  delete dummy_filter.trackingID;
  const [finalData, setFinalData] = useState(dummy_filter);
  const title = useRef(null);
  const toast = useToast();
  const description = useRef(null);
  const initImage = [
    default_product_image,
    default_product_image,
    default_product_image,
    default_product_image,
    default_product_image,
    default_product_image,
    default_product_image,
    default_product_image,
  ];
  const ImageUploadComponent = ({ position }) => {
    const [isDragging, setIsDragging] = useState(false);

    const handleDragOver = (event) => {
      event.preventDefault();
      setIsDragging(true);
    };

    const handleDragLeave = () => {
      setIsDragging(false);
    };

    const handleDrop = (event) => {
      event.preventDefault();
      setIsDragging(false);

      const file = event.dataTransfer.files[0];
      if (file) {
        const mockEvent = {
          target: {
            files: [file],
          },
        };
        handleImageUpload(mockEvent, position);
      }
    };

    const handlePaste = (event) => {
      const items = event.clipboardData.items;
      for (let i = 0; i < items.length; i++) {
        if (items[i].kind === "file" && items[i].type.startsWith("image/")) {
          const file = items[i].getAsFile();
          const mockEvent = {
            target: {
              files: [file],
            },
          };
          handleImageUpload(mockEvent, position);
          break;
        }
      }
    };

    useEffect(() => {
      window.addEventListener("paste", handlePaste);
      return () => {
        window.removeEventListener("paste", handlePaste);
      };
    }, []);
    return (
      <Box
        hidden={displayImage[position - 1] === default_product_image}
        mt={"10px"}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        border={isDragging ? "2px dashed #3182ce" : "2px dashed transparent"}
      >
        <Input
          id={`fileInput${position}`}
          display={"none"}
          type="file"
          onChange={(event) => handleImageUpload(event, position)}
          name="myFile"
          onPaste={handlePaste}
        />
        <label htmlFor={`fileInput${position}`}>
          <Box cursor="pointer" position="relative" h="100px">
            <Image
              src={displayImage[position]}
              alt="Default Image"
              h={"100px"}
              w={"100px"}
              objectFit="cover"
            />
            <Box
              hidden={displayImage[position] === default_product_image}
              display="flex"
              justifyContent="center"
              alignItems="center"
              position="absolute"
              top="5px"
              right="5px"
              width="20px"
              height="20px"
              borderRadius="50%"
              backgroundColor="white"
            >
              <Icon
                opacity={"1"}
                pointerEvents="auto"
                onClick={(e) => handleDeleteImage(e, position)}
                as={MdClose}
                color="red"
                cursor="pointer"
              />
            </Box>
          </Box>
        </label>
      </Box>
    );
  };
  const [selectedImage, setSelectedImage] = useState([]);
  const [displayImage, setdisplayImage] = useState(initImage);
  const handleImageUpload = (event, pos) => {
    let pics = [...selectedImage];
    let blob = [...displayImage];
    if (event.target.files[0] !== undefined) {
      pics[selectedImage.length] = {
        pic: event.target.files[0],
        position: pos + 1,
      };
      blob[pos] = URL.createObjectURL(event.target.files[0]);
      setSelectedImage(pics);
      setdisplayImage(blob);
      event.target.value = "";
    }
  };
  const handleDeleteImage = (e, pos) => {
    e.preventDefault();
    let pics = selectedImage.filter((ele, i) => i !== pos);
    let blob = [...displayImage];
    URL.revokeObjectURL(blob[pos]);
    blob[pos] = default_product_image;
    setSelectedImage(pics);
    setdisplayImage(blob);
  };
  const addProduct = () => {
    if (title.current.value == "" || description.current.value == "") {
      toast({
        title: "Fill all fields",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const formData = new FormData();
    const images = [];
    const id = finalData.id;
    selectedImage.forEach((e) => {
      formData.append(
        "myFile",
        e.pic,
        `${finalData.orderID.split("-")[0]}_${
          filterData[0].description.length
        }_${e.position}.jpg`
      );
      images.push(
        `${finalData.orderID.split("-")[0]}_${
          filterData[0].description.length
        }_${e.position}.jpg`
      );
    });
    formData.append(
      "data",
      JSON.stringify({
        title: title.current.value,
        description: description.current.value,
        image: images,
        status:"active",
        stage: [
          {
            stage: "order received",
            date: finalData.dates.orderDate,
          },
        ],
      })
    );
    setdisplayImage(initImage);
    setSelectedImage([]);
    title.current.value = "";
    description.current.value = "";
    dispatch(insertProduct(formData, id, token, toast)).then(() => {
      onInsertProductClose();
    });
  };
  return (
    <Box mt={"10px"}>
      <Flex direction={"column"}>
        <Input ref={title} mt={"10px"} placeholder="Title" />
        <Flex gap={"10px"}>
          <Textarea
            ref={description}
            w={"50%"}
            mt={"10px"}
            resize={"none"}
            h={"250px"}
            placeholder="Description"
          />
          <Box>
            <SimpleGrid columns={4} gap={"5px"}>
              <ImageUploadComponent
                position={0}
                handleDeleteImage={handleDeleteImage}
                handleImageUpload={handleImageUpload}
              />
              <ImageUploadComponent
                position={1}
                handleDeleteImage={handleDeleteImage}
                handleImageUpload={handleImageUpload}
              />
              <ImageUploadComponent
                position={2}
                handleDeleteImage={handleDeleteImage}
                handleImageUpload={handleImageUpload}
              />
              <ImageUploadComponent
                position={3}
                handleDeleteImage={handleDeleteImage}
                handleImageUpload={handleImageUpload}
              />
              <ImageUploadComponent
                position={4}
                handleDeleteImage={handleDeleteImage}
                handleImageUpload={handleImageUpload}
              />
              <ImageUploadComponent
                position={5}
                handleDeleteImage={handleDeleteImage}
                handleImageUpload={handleImageUpload}
              />
              <ImageUploadComponent
                position={6}
                handleDeleteImage={handleDeleteImage}
                handleImageUpload={handleImageUpload}
              />
              <ImageUploadComponent
                position={7}
                handleDeleteImage={handleDeleteImage}
                handleImageUpload={handleImageUpload}
              />
            </SimpleGrid>
          </Box>
        </Flex>
        <Button mt={"10px"} colorScheme="blue" onClick={addProduct}>
          Add Product
        </Button>
      </Flex>
    </Box>
  );
};

export default InsertProduct;
