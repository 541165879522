import { Box, Center, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";

const ReportOverview = () => {
  let time = useSelector((store) => store.app.lastgenerated);
  const reportcount = useSelector((store) => store.app.reportCount);
  return (
    <Box border={"1px solid black"} w={"100%"} mb={"10px"}>
      <Flex gap={"10px"} bgColor={"#fafafa"} borderBottom={"1px solid black"}>
        <Text m={"5px 0px 5px 15px"} fontSize={["12px","18px","20px","25px"]}>
          Production Overview
        </Text>
        <Center fontSize={["15px","20px","20px","20px"]}>taken at {time}</Center>
      </Flex>
      <SimpleGrid columns={[4,7]} fontSize={["12px","18px","20px","25px"]}>
        <Box pl={"15px"} pt={"15px"} borderRight={"1px solid black"} borderBottom={window.innerWidth<=800?"1px solid black":null}>
          <Text>Total Ordered</Text>
          <Text fontWeight={"500"} fontSize={"30px"}color={"green"}>
            {reportcount.total || 0}
          </Text>
        </Box>
        <Box ml={"15px"} mt={"15px"}>
          <Text>Total Units</Text>
          <Text fontWeight={"500"} fontSize={"30px"} color={"blue"}>
            {reportcount.products || 0}
          </Text>
        </Box>
        <Box ml={"15px"} mt={"15px"}>
          <Text>Active Units</Text>
          <Text fontWeight={"500"} fontSize={"30px"}>
            {reportcount.active || 0}
          </Text>
        </Box>
        <Box ml={"15px"} mt={"15px"}>
          <Text>Hold Units</Text>
          <Text fontWeight={"500"} fontSize={"30px"}>
            {reportcount.hold || 0}
          </Text>
        </Box>
        <Box ml={"15px"} mt={"15px"}>
          <Text>Shipped Units</Text>
          <Text fontWeight={"500"} fontSize={"30px"}>
            {reportcount.shipped || 0}
          </Text>
        </Box>
        <Box ml={"15px"} mt={"15px"}>
          <Text>Deliverd Units</Text>
          <Text fontWeight={"500"} fontSize={"30px"}>
            {reportcount.delivered || 0}
          </Text>
        </Box>
        <Box ml={"15px"} mt={"15px"}>
          <Text>Cancelled Units</Text>
          <Text fontWeight={"500"} fontSize={"30px"}>
            {reportcount.deleted || 0}
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default ReportOverview;
