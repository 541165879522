import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";

const PrivateRoute = ({ children }) => {
  const token = useSelector((store) => store.auth.token);
  if (token == "") {
    return <Navigate to={"/"} />;
  } else {
    return children;
  }
};

export default PrivateRoute;
