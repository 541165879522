import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import "../table.css";
import "./reprttable.css";

import { Link } from "react-router-dom";
import { MdOutlineContentCopy } from "react-icons/md";
import { FaLink } from "react-icons/fa";
import { capitalizeWords } from "../../Component/capitalWords";
import { table_filter } from "../../universal_variable";
const ReportTable = () => {
  const handleCopyLink = useCallback(async (text) => {
    setCopyLink(true);
    setTimeout(() => setCopyLink(false), 2000);

    if (navigator.clipboard) {
      try {
        const permissionGranted = await navigator.permissions.query({
          name: "clipboard-write",
        });
        if (
          permissionGranted.state === "granted" ||
          permissionGranted.state === "prompt"
        ) {
          await navigator.clipboard.writeText(text);
          toast({
            title: "Text copied to clipboard",
            status: "success",
            duration: 1000,
            isClosable: true,
          });
        } else {
          throw new Error("Clipboard-write permission denied");
        }
      } catch (err) {
        toast({
          title: "Could not copy text",
          description: err.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Clipboard API not supported",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, []);
  const [copylink, setCopyLink] = useState(false);
  const preview = useSelector((store) => store.app.reportData);
  const toast = useToast();
  return (
    <table style={{ width: "100%", color: "white" }}>
      <thead>
        <tr backgroundColor={"#0c1559"} color={"white"}>
          <th id="reporttable" w={"100px"} border={"1px solid white"} p={"5px"}>
            <Center>ID No.</Center>
          </th>
          <th id="reporttable" w={"250px"} border={"1px solid white"} p={"5px"}>
            <Center>Order Data</Center>
          </th>
          <th
            id="reporttable"
            minW={"500px"}
            border={"1px solid white"}
            p={"5px"}
          >
            <Center>Order Description</Center>
          </th>
          <th id="reporttable" border={"1px solid white"} p={"5px"}>
            <Center>Order Receiving Date</Center>
          </th>
          <th id="reporttable" border={"1px solid white"} p={"5px"}>
            <Center>Expected Delivery Date</Center>
          </th>
          <th id="reporttable">
            <Center>Courier Details</Center>
          </th>
          <th
            id="deliverydate"
            bgColor={"#006400"}
            border={"1px solid white"}
            p={"5px"}
          >
            <Center>Delivery Date</Center>
          </th>
        </tr>
      </thead>
      <tbody borderTop={"1px solid black"}>
        {preview.length > 0 &&
          preview.map((ele) => {
            const genLink = `https://rumaenterprise.com/track/?id=${ele?.trackingID}`;
            return (
              <tr style={{ color: "black" }}>
                <td border="1px solid black">
                  <Center>{ele.id}</Center>
                </td>
                <td border="1px solid black" p="0px">
                  <Flex direction="column">
                    <Text fontWeight="bold" fontSize="18px" mt="10px">
                      <Center>Dealer/School:</Center>
                    </Text>
                    <Text textAlign="center">
                      {ele?.orderData?.dealer?.distributor_name}(
                      {ele?.orderData?.dealer?.distributor_code})
                    </Text>
                  </Flex>
                  <Flex direction="column">
                    <Text fontWeight="bold" fontSize="18px">
                      <Center>Address:</Center>
                    </Text>
                    <Text textAlign="center">
                      {ele?.orderData?.dealer?.address}
                    </Text>
                  </Flex>
                  <Flex direction="column">
                    <Text fontWeight="bold" fontSize="18px">
                      <Center>Order taken by:</Center>
                    </Text>
                    <Text textAlign="center">
                      {ele?.orderData?.orderBy?.biller_name}
                    </Text>
                  </Flex>
                  <Flex direction="column">
                    <Text fontWeight="bold" fontSize="18px">
                      <Center>Tracking ID:</Center>
                    </Text>
                    <Text
                      textAlign="center"
                      fontSize="20px"
                      color="red"
                      fontWeight="bold"
                    >
                      {ele?.trackingID}
                    </Text>
                  </Flex>
                  <Flex direction="column" mt="10px">
                    <Link to={genLink} target="_blank">
                      <Text
                        fontSize="14px"
                        textAlign="center"
                        color="blue"
                        textDecoration="underline"
                        fontWeight="bold"
                      >
                        {genLink}
                      </Text>
                    </Link>
                    <Center>
                      <Button
                        mt="5px"
                        mb="10px"
                        bgColor="#00FF00"
                        borderRadius="0px"
                        fontSize="17px"
                        leftIcon={
                          copylink ? <MdOutlineContentCopy /> : <FaLink />
                        }
                        _hover={{ bgColor: "#218560", color: "white" }}
                        _active={{ bgColor: "" }}
                        onClick={() => handleCopyLink(genLink)}
                      >
                        {copylink ? "COPIED!" : "COPY LINK"}
                      </Button>
                    </Center>
                  </Flex>
                </td>
                <td border="1px solid black" p="0px">
                  {ele?.description?.map((elem, index) => {
                    return (
                      <Box
                        key={index}
                        mr="10px"
                        borderBottom={
                          ele?.description?.[index + 1]
                            ? "1px solid #ddd"
                            : "none"
                        }
                      >
                        <Flex mt="5px" justifyContent="space-between">
                          <Text
                            m="15px"
                            mr="0px"
                            color="#218560"
                            fontWeight="bold"
                          >
                            Product {index + 1}
                          </Text>
                          <Text>
                            {elem.stage != undefined
                              ? capitalizeWords(
                                  elem?.stage[elem?.stage?.length - 1]?.stage
                                )
                              : ""}
                          </Text>
                        </Flex>
                        <Text m="10px">{elem?.title}</Text>
                        <Text mt="15px" m="10px" whiteSpace="pre-wrap">
                          {elem?.description}
                        </Text>
                        <SimpleGrid columns={4} m="3px" gap="5px">
                          {elem?.image?.map((imgs, i) => {
                            const url = `${process.env.REACT_APP_BACKEND_URL}/images/${imgs}`;
                            return (
                              <Box key={i} mr="6px">
                                <Popover>
                                  <PopoverTrigger>
                                    <Image
                                      m="10px"
                                      h="100px"
                                      src={url}
                                      alt={imgs}
                                    />
                                  </PopoverTrigger>
                                  <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverBody>
                                      <Link to={url} target="_blank">
                                        <Image src={url} alt={imgs} />
                                      </Link>
                                    </PopoverBody>
                                  </PopoverContent>
                                </Popover>
                              </Box>
                            );
                          })}
                        </SimpleGrid>
                      </Box>
                    );
                  })}
                </td>
                <td border="1px solid black" p="5px">
                  <Center>
                    <Text fontWeight="bold">{ele.dates.orderDate}</Text>
                  </Center>
                </td>
                <td border="1px solid black" p="5px">
                  <Center>
                    <Text fontWeight="bold">{ele.dates.expectedDate}</Text>
                  </Center>
                </td>
                <td border="1px solid black" p="0px">
                  {ele?.courier?.map(({ courier, awb }, i) => {
                    const data = ele?.courier?.filter(
                      (el) =>
                        el.courier === ele?.courier?.[0].courier &&
                        el.awb === ele?.courier?.[0].awb
                    );
                    const flag = data.length === ele?.courier?.length;
                    return (
                      <Box key={i} w="180px" p="0px 5px" hidden={i > 0 && flag}>
                        <Center>
                          <Text fontWeight="bold" color="red" hidden={flag}>
                            {courier && `Product ${i + 1}`}
                          </Text>
                        </Center>
                        <Center>
                          <Text mb={"15px"}>
                            {courier && capitalizeWords(courier)}
                          </Text>
                        </Center>
                        <Center>
                          <Text whiteSpace="pre-wrap">
                            {(courier && awb)}
                          </Text>
                        </Center>
                      </Box>
                    );
                  })}
                </td>
                <td border="1px solid black" p="0px">
                  {ele?.description?.map((f, i) => {
                    const data = f?.stage?.filter(
                      (el) =>
                        table_filter.complete.includes(el.stage)
                    );
                    return (
                      <Box key={i} p="0px 5px" >
                        <Text
                          textAlign={"center"}
                          fontWeight={"bold"}
                          color={"#006400"}
                        >
                          {data[data.length-1]?.date||"Not Delivered Yet"}
                        </Text>
                      </Box>
                    );
                  })}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default ReportTable;
