import { Box, Icon, Image, Input, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import default_product_image from "../Resources/default_product_image.png";
import { MdClose } from "react-icons/md";

const ImageUploadComponent = ({
  position,
  handleImageUpload,
  handleDeleteImage,
  displayImage,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const toast = useToast();

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    const file = event.dataTransfer.files[0];
    if (file) {
      const mockEvent = {
        target: {
          files: [file],
        },
      };
      handleImageUpload(mockEvent, position);
    }
  };

  const handlePaste = (event) => {
    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].kind === "file" && items[i].type.startsWith("image/")) {
        const file = items[i].getAsFile();
        const mockEvent = {
          target: {
            files: [file],
          },
        };
        handleImageUpload(mockEvent, position);
        break;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("paste", handlePaste);
    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, []);
  const handleHidden = () => {
    if (displayImage[position] != default_product_image) {
      return false;
    } else {
      const frontImage = displayImage.filter(
        (e, i) => i > position && e == default_product_image
      );
      const lengthImage = displayImage.length - 1 - position;
      if (frontImage.length == lengthImage) {
        if (displayImage[position - 1] != default_product_image) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  };
  return (
    <Box
      hidden={handleHidden()}
      mt={"10px"}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      border={isDragging ? "2px dashed #3182ce" : "2px dashed transparent"}
    >
      <Input
        accept="image/*"
        id={`fileInput${position}`}
        display={"none"}
        type="file"
        onChange={(event) => {
          if (event.target.files[0] && event.target.files[0].type === "") {
            toast({
              title: "Image type not found",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          } else {
            handleImageUpload(event, position);
          }
        }}
        name="myFile"
        onPaste={handlePaste}
      />
      <label htmlFor={`fileInput${position}`}>
        <Box cursor="pointer" position="relative" h="100px">
          <Image
            src={displayImage[position]}
            alt="Default Image"
            h={"100px"}
            w={"100px"}
            objectFit="cover"
          />
          <Box
            hidden={displayImage[position] === default_product_image}
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            top="5px"
            right="5px"
            width="20px"
            height="20px"
            borderRadius="50%"
            backgroundColor="white"
          >
            <Icon
              opacity={"1"}
              pointerEvents="auto"
              onClick={(e) => handleDeleteImage(e, position)}
              as={MdClose}
              color="red"
              cursor="pointer"
            />
          </Box>
        </Box>
      </label>
    </Box>
  );
};

export default ImageUploadComponent;
