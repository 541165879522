import React, { useRef } from "react";
import { ReactBarcode } from "react-jsbarcode";
import {
  Box,
  Center,
  Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from "@chakra-ui/react";

const ProductBarcode = ({ value, name }) => {
  const {
    isOpen: isBarAlertOpen,
    onOpen: onBarAlertOpen,
    onClose: onBarAlertClose,
  } = useDisclosure();
  const barcodeRef = useRef();
  const option = {
    format: "CODE128",
    displayValue: true,
    height: 30, // Reduced height
  };

  const handlePrint = () => {
    if (barcodeRef.current) {
      const printWindow = window.open("", "", "height=600,width=800");
      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <body>
          <head>
          <style>
          .barcode{
          margin-top:-7px
          }
            .print-content {
              font-size: 12px;
              font-weight:bold;
              text-align:center;
              font-family:arial
            }
              .company-name{
              border-radius:10px;
              border:1px solid black;
              text-align:center;
              }
          </style>
          </head>
          <div class="company-name">
          <text>Ruma Enterprise</text>
          </div>
            <div class="print-content">
              ${barcodeRef.current.innerHTML}
            </div>
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      onBarAlertClose()
    }
  };

  return (
    <Box w={"100%"}>
      <AlertDialog
        size={"sm"}
        isCentered
        isOpen={isBarAlertOpen}
        onClose={onBarAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Barcode Preview
            </AlertDialogHeader>

            <AlertDialogBody>
              <Box ref={barcodeRef} mb={"10px"}>
                <Center>
                  <Text mb={"-5px"} fontSize={"15px"} fontWeight={"bold"}>{name.toUpperCase()}</Text>
                </Center>
                <Center className="barcode">
                  <ReactBarcode value={`${value}`} options={option} />
                </Center>
              </Box>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button colorScheme="red" onClick={onBarAlertClose} mr={"10px"}>
                Cancel
              </Button>
              <Button onClick={handlePrint}>Print Barcode</Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Center>
        <Button onClick={onBarAlertOpen}>Barcode Preview</Button>
      </Center>
      
    </Box>
  );
};

export default ProductBarcode;
