import React, { useEffect, useRef, useState } from "react";
import { ReactBarcode } from "react-jsbarcode";
import html2canvas from "html2canvas";
import {
  Box,
  Center,
  Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure,
  Button,
  Input,
  Flex,
  Select,
  Image,
} from "@chakra-ui/react";
import rumaqr from "../Resources/rumaqr.png";
const BarcodeGen = ({ client, value, address, name, pos, tag = true }) => {
  const currentDate = new Date().toISOString().split("T")[0];
  const date = currentDate.split("-").reverse().join("/");
  const [load, setLoad] = useState(false);
  const [userValue, setUserValue] = useState({
    size: "",
    unit: "",
    quantity: "",
    mrp: "",
  });
  const {
    isOpen: isBarAlertOpen,
    onOpen: onBarAlertOpen,
    onClose: onBarAlertClose,
  } = useDisclosure();
  const barcodeNameRef = useRef();
  const barcodeProductRef = useRef();
  const barcodeSizeRef = useRef();
  const barcodeAddressRef = useRef();
  const barcodeImageRef = useRef();
  const barcodeRef = useRef();
  const option = {
    format: "CODE128",
    displayValue: true,
    height: 50,
  };
  const downloadBarcode = () => {
    setLoad(true);
    if (barcodeRef.current) {
      barcodeRef.current.hidden = false;
      html2canvas(barcodeRef.current).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = `${value}/${pos}.png`;
        link.click();
        barcodeRef.current.hidden = true;
        onBarAlertClose();
        setUserValue({
          size: "",
          unit: "",
          quantity: "",
          mrp: "",
        });
      });
    }
    setLoad(false);
  };
  const handleUserData = (value, field) => {
    const temp = { ...userValue };
    temp[field] = value;
    setUserValue(temp);
  };
  const handlePrint = () => {
    const printWindow = window.open("", "", "height=600,width=800");
    printWindow.document.open();
    if (tag) {
      printWindow.document.write(`
          <html>
            <head>
              <title>Print</title>
              <style>
               .barcode{
            margin-top:-7px
            }
              .print-content {
                font-size: 18px;
                font-weight:bold;
                text-align:center;
              font-family:arial
              }
              .company-name{
                border-radius:10px;
                font-size: 20px;
                border:1px solid black;
                text-align:center;
                font-weight:bold;
                margin-bottom:-10px;
                }
                .mrgin{
                margin-top:-10px;
                font-size:18px
                }
                .size{
                margin-bottom:-10px;
                }
              </style>
            </head>
            <body>
            <div class="company-name">
            <text>Ruma Enterprise</text>
            </div>
              <div class="print-content">
              <div class="mrgin">
              ${barcodeNameRef.current.innerHTML}
              </div>
              <div class="mrgin">
              ${barcodeProductRef.current.innerHTML}
              </div>
              <div class="mrgin size">
              ${barcodeSizeRef.current.innerHTML}
              </div>
              <div>
              ${barcodeRef.current.innerHTML}
              </div>
                
              </div>
            </body>
          </html>
        `);
    } else {
      printWindow.document.write(`
          <html>
            <head>
              <title>Print</title>
              <style>
               .barcode{
            margin-top:-7px
            }
              .print-content {
                font-size: 18px;
                font-weight:bold;
                text-align:center;
              font-family:arial
              }
              .company-name{
                border-radius:10px;
                font-size: 20px;
                border:1px solid black;
                text-align:center;
                font-weight:bold;
                margin-bottom:-10px;
                }
                .mrgin{
                margin-top:-10px;
                font-size:18px
                }
                .size{
                margin-bottom:-10px;
                }
                .qr{
                margin-top:12px;
                display:flex;
                }
                img{
                height:100px;
                margin-top:8px;
                }
              </style>
            </head>
            <body>
            <div class="company-name">
            <text>Ruma Enterprise</text>
            </div>
              <div class="print-content">
              <div class="qr">
              <div>
              <div class="mrgin">
              ${barcodeNameRef.current.innerHTML}
              </div>
              <div class="mrgin">
              ${barcodeAddressRef.current.innerHTML}
              </div>
              <div class="mrgin">
              ${barcodeProductRef.current.innerHTML}
              </div>
              </div>
              ${barcodeImageRef.current.innerHTML}
              </div>
              <div class="mrgin size">
              ${barcodeSizeRef.current.innerHTML}
              </div>                
              </div>
            </body>
          </html>
        `);
    }
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };
  return (
    <Box
      onClick={onBarAlertOpen}
      w={"100%"}
      _hover={{ bgColor: "blue", color: "white" }}
    >
      <AlertDialog
        size={window.innerWidth <= 800 ? "full" : "ct"}
        isCentered
        isOpen={isBarAlertOpen}
        onClose={onBarAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold"
            ></AlertDialogHeader>

            <AlertDialogBody>
              <Box ml={window.innerWidth <= 800 ? "0px" :"25%"} w={"350px"} mb={"10px"}>
                <Flex>
                  <Box w={!tag ? "250px" : null}>
                    <Center ref={barcodeNameRef}>
                      <Text
                        textAlign={"center"}
                        whiteSpace={"pre-wrap"}
                        w={"350px"}
                        m={"0px 7px"}
                        mb={"2px"}
                      >
                        {client}
                      </Text>
                    </Center>
                    <Center ref={barcodeProductRef}>
                      <Text
                        textAlign={"center"}
                        whiteSpace={"pre-wrap"}
                        w={"350px"}
                        m={"0px 7px"}
                        mb={"2px"}
                      >
                        {name}
                      </Text>
                    </Center>
                    {!tag ? (
                      <Center ref={barcodeAddressRef}>
                        <Text
                          textAlign={"center"}
                          whiteSpace={"pre-wrap"}
                          w={"350px"}
                          m={"0px 7px"}
                          mb={"2px"}
                        >
                          {address}
                        </Text>
                      </Center>
                    ) : null}
                  </Box>
                  <Box ref={barcodeImageRef}>
                    {!tag ? <Image h={"80px"} src={rumaqr} /> : null}
                  </Box>
                </Flex>

                <Center ref={barcodeSizeRef}>
                  <Text
                    textAlign={"center"}
                    whiteSpace={"pre-wrap"}
                    w={"350px"}
                    m={"0px 7px"}
                    mb={"2px"}
                  >
                    Size: {userValue.size} {userValue.unit},
                    {tag ? `Qty:${userValue.quantity} PCS,` : null}
                    {!tag ? `MRP:${userValue.mrp},` : null} MFD: {date}
                  </Text>
                </Center>
                {tag ? (
                  <Center ref={barcodeRef}>
                    <ReactBarcode value={`${value}/${pos}`} options={option} />
                  </Center>
                ) : null}
              </Box>

              <Flex gap={"10px"}>
                <Input
                  type="number"
                  placeholder="Enter Size"
                  onChange={(e) => handleUserData(e.target.value, "size")}
                />
                <Select
                  onChange={(e) => handleUserData(e.target.value, "unit")}
                >
                  <option hidden>Select Unit</option>
                  <option value={"inch"}>inch</option>
                  <option value={"meter"}>meter</option>
                  <option value={"cm"}>cm</option>
                  <option value={"mm"}>mm</option>
                  <option value={"roll"}>roll</option>
                </Select>
                {tag ? (
                  <Input
                    type="number"
                    placeholder="Enter Quantity"
                    onChange={(e) => handleUserData(e.target.value, "quantity")}
                  />
                ) : null}
                {!tag ? (
                  <Input
                    type="number"
                    placeholder="Enter MRP"
                    onChange={(e) => handleUserData(e.target.value, "mrp")}
                  />
                ) : null}
              </Flex>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="red" onClick={onBarAlertClose} mr={"10px"}>
                Cancel
              </Button>
              <Button colorScheme="blue" mr={"10px"} onClick={handlePrint}>
                {tag ? "Print Barcode" : "Print MRP Tag"}
              </Button>
              <Button
                isLoading={load}
                colorScheme="green"
                onClick={downloadBarcode}
              >
                download
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {tag ? <Text>Print Packet Barcode</Text> : <Text>Print MRP Tag</Text>}
    </Box>
  );
};

export default BarcodeGen;
